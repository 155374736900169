
import Vue from "vue";
import Component from "vue-class-component";
import RequestList from "@/components/RequestList";
import RequestStatusChangedPopup from "@/components/RequestStatusChangedPopup";
import { requestModule } from "@/store/modules/request";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import { Routes } from "@/router/routes";
import { RequestState } from "@/models/request/Request";
import { isUserInRoles } from "@/services/userUtils";
import { RequestAdminDataUpdateDTO } from "@/models/request/RequestAdminDataUpdateDTO";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import { Market } from "@/models/market/Market";
import { DxSelectBox } from "devextreme-vue/select-box";
import { Watch } from "vue-property-decorator";
import { LookupEntity } from "@/models/LookupEntity";
import RequestColumnSettings from "@/components/RequestColumnSettings";
import {
    RequestGridConfig,
    defaultConfig
} from "@/models/request/RequestGridConfig";
import queryService from "@/services/queryService";
import { Route } from "vue-router";
import BaseButton from "@/components/BaseButton";
import { RequestListColumnName } from "@/models/request/list/RequestListColumnName";
import {
    RequestListOptions,
    getDefaultRequestListOptions
} from "@/models/request/list/RequestListOptions";
import {
    standardRequestListFilterValues,
    standardRequestListPaginationValues,
    standardRequestListSortingValues
} from "@/consts/requestListOptionsValues";
import CustomStore from "devextreme/data/custom_store";
import { CustomerStatusChangedEmail } from "@/models/request/CustomerStatusChangedEmail";

@Component({
    components: {
        RequestList,
        DxSelectBox,
        RequestColumnSettings,
        BaseButton,
        RequestStatusChangedPopup
    },
    methods: {
        ...requestModule.mapActions({
            loadRequests: RequestActions.LoadRequests,
            updateAdminData: RequestActions.UpdateRequestAdminData,
            loadRequestsByMarket: RequestActions.LoadRequestsByMarket,
            exportDashboard: RequestActions.ExportDashboard,
            createDataSource: RequestActions.CreateRequestDataSource,
            loadInfo: RequestActions.LoadInfo
        }),
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        })
    },
    computed: {
        ...requestModule.mapGetters({
            requests: RequestGetters.Requests,
            isLoading: RequestGetters.IsLoading,
            requestGridConfig: RequestGetters.RequestGridConfig
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    }
})
export default class Home extends Vue {
    protected readonly requestGridConfig!: RequestGridConfig;
    private readonly loadInfo!: (marketId?: number) => Promise<void>;
    private readonly createDataSource!: (payload: {
        state?: string;
        marketId?: number;
        marketRequestState?: string;
    }) => Promise<CustomStore>;
    private readonly loadMarkets!: () => Promise<void>;
    protected readonly updateAdminData!: (
        payload: RequestAdminDataUpdateDTO
    ) => Promise<void>;
    private readonly exportDashboard!: (
        payload: RequestGridConfig
    ) => Promise<void>;

    protected readonly requests!: RequestListItem[];
    protected readonly isLoading!: boolean;
    protected readonly markets!: Market[];
    public requestListOptions: RequestListOptions =
        getDefaultRequestListOptions();
    get config(): RequestGridConfig {
        return this.requestGridConfig ?? defaultConfig;
    }

    protected requestDataSource: CustomStore | null = null;
    protected requestCustomerStatusChangedPopupVisibility = false;
    protected customerStatusChangedEmail =
        null as CustomerStatusChangedEmail | null;

    async created(): Promise<void> {
        this.setRequestListOptionsFromQuery(this.$route.query);

        const market = queryService.getValues(this.$route.query, {
            marketId: 0
        });
        if (market.marketId) {
            this.currentMarket = market.marketId;
            this.requestDataSource = await this.createDataSource({
                marketId: market.marketId
            });
        } else {
            this.requestDataSource = await this.createDataSource({});
        }
        if (this.hasUserAccessToMarket) await this.loadMarkets();
    }

    get requestListColumns(): Array<RequestListColumnName | null> {
        return [
            this.config.typeOfRequest ? "requestType" : null,
            this.config.customer ? "customer" : null,
            this.config.customerType ? "customerType" : null,
            this.config.expectedVolume ? "expectedVolume" : null,
            this.config.customerStatus ? "customerStatus" : null,
            this.config.marketDeadline ? "marketDeadline" : null,
            this.isUserHQ && this.config.customerDeadline
                ? "customerDeadline"
                : null,
            this.config.stateTool ? "state" : null,
            this.config.IKAM ? "IKAM" : null,
            this.config.comment ? "adminComment" : null,
            this.config.supplyScenarios ? "supplyScenarios" : null,
            this.config.totalFleet ? "totalFleet" : null,
            this.config.countMarkets ? "totalMarkets" : null,
            this.config.modifiedBy ? "modifiedBy" : null
        ];
    }

    viewRequest(requestId: number): void {
        const request = this.requests.find((r) => r.requestId === requestId);
        if (request) {
            let route = Routes.Request;
            if (request.state === RequestState.New) {
                route = Routes.Draft;
            } else if (request.state === RequestState.Completed) {
                route = Routes.ArchivedRequest;
            }
            this.$router.push(route + requestId);
        }
    }
    public currentMarket = 0;
    async onMarketValueChanged(e: {
        value: number;
        previousValue: number;
    }): Promise<void> {
        if (!e.value && !e.previousValue) {
            return;
        } else {
            await this.recreateDataSource(e.value);
            await this.loadInfo(e.value);
        }
    }

    async recreateDataSource(value?: number): Promise<void> {
        if (value) {
            queryService.setValues(this.$route.query, { marketId: value });
            this.requestDataSource = await this.createDataSource({
                marketId: value
            });
        } else {
            const currentRoute = this.$route;
            const query = Object.assign({}, this.$route.query);
            delete query.marketId;
            const newRoute = {
                path: currentRoute.path,
                query: query
            };
            this.$router.push(newRoute);
            this.requestDataSource = await this.createDataSource({});
        }
    }

    async updateAdminDataAndReloadRequestList(
        payload: RequestAdminDataUpdateDTO
    ): Promise<void> {
        await this.updateAdminData(payload);
        if (this.requestDataSource) {
            this.refreshChildData();
        }
    }
    refreshChildData(): void {
        const requestList = this.$refs.requestList as InstanceType<
            typeof RequestList
        >;
        if (requestList) {
            requestList.reloadData();
        }
    }

    get isUserHQ(): boolean {
        return isUserInRoles(["Admin", "SU", "HV"]);
    }

    get isUserAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }
    get hasUserAccessToMarket(): boolean {
        return isUserInRoles(["Admin", "SU", "MSU", "MU"]);
    }

    get currentMarkets(): LookupEntity<unknown>[] {
        return [
            { id: null, name: "---" },
            ...this.markets.map((market) => ({
                id: market.marketId,
                name: market.name
            }))
        ];
    }

    setRequestListOptionsFromQuery(query: Route["query"]): void {
        this.requestListOptions.filter = queryService.getValues(
            query,
            standardRequestListFilterValues
        );
        this.requestListOptions.pagination = queryService.getValues(
            query,
            standardRequestListPaginationValues
        );
        this.requestListOptions.sorting = queryService.getValues(
            query,
            standardRequestListSortingValues
        );
    }

    requestCustomerStatusChanged(
        customerStatusChangedEmail: CustomerStatusChangedEmail
    ): void {
        this.requestCustomerStatusChangedPopupVisibility = true;
        this.customerStatusChangedEmail = customerStatusChangedEmail;
    }

    @Watch("requestListOptions", { deep: true })
    updateRequestListOptions(): void {
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.filter
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.pagination
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.sorting
        });
    }
}
