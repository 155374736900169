import router from "@/router";
import { Routes } from "@/router/routes";
import { AxiosError } from "axios";

export function handleApiError(
    error: AxiosError,
    errorCodes: number[],
    path?: string
): void {
    const response = error.response;
    if (response?.status && errorCodes.includes(response.status)) {
        const status = response.status;
        const fullPath = (path ? path : Routes.Error) + status;
        if (router.currentRoute.path !== fullPath) router.push(fullPath);
    }
}

export function convertErrorObjectToString(
    errorObject: Record<string, string[]>
): string {
    let result = "";
    for (const [key, messages] of Object.entries(errorObject)) {
        // Each key followed by its messages
        result += `${key}: `;
        // Join messages if there are multiple, or just append the single message
        result += messages.join(", ");
        result += "\n"; // Newline for separating different errors
    }
    return result.trim(); // Trim to remove any extra newline at the end
}
