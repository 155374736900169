import userApi from "@/api/userApi";
import { User } from "@/models/User";
import { RootState } from "@/store";
import { ActionContext, ActionTree, Commit } from "vuex";
import { UserState } from ".";
import { saveData } from "@/services/saveData";
import { UserMutations } from "./mutations";
import { getValidationErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { makeRequest } from "@/services/requestUtils";

export enum UserActions {
    LoadUsersByMarket = "loadUsersByMarket",
    LoadIKAMs = "loadIKAMs",
    LoadUsers = "loadUsers",
    DeleteUserById = "deleteUserById",
    LoadUserDetailsById = "loadUserDetailsById",
    AddNewUser = "addNewUser",
    CommitUserUpdate = "commitUserUpdate",
    CreateUser = "createUser",
    GetTwoFactorRecoveryCode = "getTwoFactorRecoveryCode",
    ExportUsers = "exportUsers"
}
async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        UserMutations.SetErrors,
        UserMutations.SetLoading,
        callback,
        onSuccess
    );
}

async function loadUsers(
    commit: Commit,
    load: () => Promise<User[]>,
    saveCommit = UserMutations.SetUsers
): Promise<void> {
    commit(UserMutations.SetLoading, true);
    try {
        const users = await load();
        commit(saveCommit, users);
    } catch {
        commit(UserMutations.SetErrors, ["Error"]);
    } finally {
        commit(UserMutations.SetLoading, false);
    }
}

const actions: ActionTree<UserState, RootState> = {
    async [UserActions.LoadUsersByMarket]({ commit }, payload: number) {
        commit(UserMutations.SetUsers, []);
        await loadUsers(commit, () => userApi.getUserByMarket(payload));
    },
    async [UserActions.LoadIKAMs]({ commit }) {
        await loadUsers(
            commit,
            () => userApi.getIKAMS(),
            UserMutations.SetIkams
        );
    },
    async [UserActions.LoadUsers]({ commit }) {
        await loadUsers(commit, userApi.getUsers);
    },
    async [UserActions.DeleteUserById]({ commit }, payload: number) {
        await userApi.deleteUser(payload);
        commit(UserMutations.RemoveUserById, payload);
    },

    async [UserActions.LoadUserDetailsById]({ commit }, payload: number) {
        commit(UserMutations.SetLoading, true);
        try {
            const user = await userApi.getUserDetails(payload);
            commit(UserMutations.SetUser, user);
        } catch {
            commit(UserMutations.SetErrors, ["Error"]);
        } finally {
            commit(UserMutations.SetLoading, false);
        }
    },
    [UserActions.AddNewUser]({ commit }) {
        const user: Partial<User> = {
            firstName: "",
            lastName: "",
            email: "",
            marketUsers: []
        };
        commit(UserMutations.SetUser, user);
    },
    async [UserActions.ExportUsers]({ commit }) {
        await makeModuleRequest(
            commit,
            () => userApi.exportUsers(),
            (file) => saveData(file.data, file.filename)
        );
    },
    async [UserActions.CommitUserUpdate](context): Promise<void> {
        try {
            if (context.state.user) {
                await userApi.updateUser(context.state.user);
            }
        } catch (e) {
            handleReponseError(e as Error, context);
        }
    },
    async [UserActions.CreateUser](context): Promise<void> {
        try {
            if (context.state.user) {
                await userApi.createUser(context.state.user);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [UserActions.GetTwoFactorRecoveryCode](context, payload: number) {
        try {
            return await userApi.getTwoFactorRecoveryCode(payload);
        } catch (e) {
            handleReponseError(e, context);
        }
    }
};

export function handleReponseError(
    error: Error,
    context: ActionContext<UserState, RootState>
): void {
    context.commit(
        UserMutations.SetErrors,
        getValidationErrorTextsFromErrorResponse(error)
    );
}

export default actions;
