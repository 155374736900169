
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";

@Component({
    components: { DxPopup }
})
export default class TermsOfUsePopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected width = 1200;
    protected height = 750;

    @Watch("popupVisible")
    visibleChanged(): void {
        if (window.innerHeight < 900) {
            this.height = 600;
        } else {
            this.height = 750;
        }
    }
}
