import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface RoleState {
    roles: string[];
    errors: string[] | null;
    loading: boolean;
}

const roleState: RoleState = {
    roles: [],
    errors: null,
    loading: false
};

const role: Module<RoleState, RootState> = {
    namespaced: true,
    state: roleState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const roleModule = createNamespacedHelpers("role");

export default role;
