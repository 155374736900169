import customerApi from "@/api/customerApi";
import { Customer } from "@/models/Customer";
import { makeRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { CustomerState } from ".";
import { UserActions } from "@/store/modules/user/actions";
import { CustomerMutations } from "./mutations";

export enum CustomerActions {
    LoadCustomers = "loadCustomers",
    LoadCustomerById = "loadCustomerById",
    AddNewCustomer = "addNewCustomer",
    CreateCustomer = "createCustomer",
    DeleteCustomer = "deleteCustomer",
    SaveCustomer = "saveCustomer"
}

async function makeCustomerRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        CustomerMutations.SetError,
        CustomerMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<CustomerState, RootState> = {
    async [CustomerActions.LoadCustomers](context): Promise<void> {
        await makeCustomerRequest(
            context.commit,
            () => customerApi.getCustomers(),
            (result) => {
                context.commit(CustomerMutations.SetCustomers, result);
            }
        );
    },
    async [CustomerActions.DeleteCustomer](
        context,
        payload: number
    ): Promise<void> {
        await makeCustomerRequest(
            context.commit,
            () => customerApi.deleteCustomer(payload),
            () => context.commit(CustomerMutations.RemoveCustomer, payload)
        );
    },
    async [CustomerActions.LoadCustomerById](
        context,
        payload: number
    ): Promise<void> {
        context.dispatch(UserActions.LoadIKAMs);
        await makeCustomerRequest(
            context.commit,
            () => customerApi.getCustomerById(payload),
            (result) =>
                context.commit(CustomerMutations.SetSelectedCustomer, result)
        );
    },
    [CustomerActions.AddNewCustomer](context): void {
        context.dispatch(UserActions.LoadIKAMs);

        const customer: Customer = {
            customerId: 0,
            name: "",
            isCustomer: true,
            ikamId: 0
        };
        context.commit(CustomerMutations.SetSelectedCustomer, customer);
    },
    async [CustomerActions.CreateCustomer](context): Promise<boolean> {
        let result = false;
        if (context.state.selectedCustomer) {
            const customer = context.state.selectedCustomer;
            await makeCustomerRequest(
                context.commit,
                () => customerApi.createCustomer(customer),
                () => {
                    result = true;
                }
            );
        }
        return result;
    },
    async [CustomerActions.SaveCustomer](context): Promise<boolean> {
        let result = false;
        if (context.state.selectedCustomer) {
            const customer = context.state.selectedCustomer;
            await makeCustomerRequest(
                context.commit,
                () => customerApi.saveCustomer(customer),
                () => {
                    result = true;
                }
            );
        }
        return result;
    }
};

export default actions;
