import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { RoleState } from ".";

export enum RoleGetters {
    Roles = "roles",
    Errors = "errors",
    Loading = "loading"
}

const getters: GetterTree<RoleState, RootState> = {
    [RoleGetters.Roles](state): string[] {
        return state.roles;
    },
    [RoleGetters.Loading](state): boolean {
        return state.loading;
    },
    [RoleGetters.Errors](state): string[] | null {
        return state.errors;
    }
};

export default getters;
