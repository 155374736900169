
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import SingleAccordion from "@/components/SingleAccordion";
import { DxDateBox } from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxButton } from "devextreme-vue/button";
import { ReleaseEmail } from "@/models/ReleaseEmail";
import notify from "devextreme/ui/notify";
import releaseEmailApi from "@/api/releaseEmailApi";
import { AxiosResponse } from "axios";
import { getValidationErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

@Component({
    components: {
        DxPopup,
        SingleAccordion,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxTextArea,
        DxFileUploader,
        DxButton,
        DxHtmlEditor,
        DxToolbar,
        DxItem
    }
})
export default class ReleaseEmailsPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected width = 1200;
    protected height = 770;

    // Current date plus 2 minutes
    protected minDate: Date = new Date(new Date(Date.now() + 2 * 60000));
    public valueContent = "";
    protected sizeValues = [
        "8pt",
        "10pt",
        "12pt",
        "14pt",
        "18pt",
        "24pt",
        "36pt"
    ];
    protected fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
    ];
    protected headerValues = [false, 1, 2, 3, 4, 5];
    public selectedItems = [{ text: "Html" }];
    get editorValueType(): unknown {
        return this.selectedItems[0].text.toLowerCase();
    }
    protected releaseEmail: ReleaseEmail = {
        releaseDateTime: new Date(),
        message: ""
    };
    @Watch("visible")
    visibleChanged(): void {
        this.releaseEmail = {
            releaseDateTime: new Date(),
            message: ""
        };
        this.minDate = new Date(new Date(Date.now() + 2 * 60000));
    }

    protected readonly maxFileSize = 30 * 1000 * 1000; // in bytes => 30MB

    uploadFile(file: File): boolean {
        if (file.size < this.maxFileSize) {
            this.releaseEmail.attachment = file;
            return true;
        } else {
            this.releaseEmail.attachment = undefined;
            notify("The maximum file size allowed is 30MB", "warning", 3000);
            return false;
        }
    }

    async submit(): Promise<void> {
        try {
            await releaseEmailApi.create(this.releaseEmail);
            notify("Success", "success", 1000);
            this.popupVisible = false;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const response: AxiosResponse = error.response;

            let errorMessage: string;
            if (response) {
                errorMessage =
                    getValidationErrorTextsFromErrorResponse(error).join(";");
            } else {
                errorMessage = "Network error!";
            }

            notify(`Error! ${errorMessage}`, "error", 3000);
        }
    }
}
