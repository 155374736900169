export interface RequestListFilter {
    requestId?: string[] | null;
    typeOfRequest?: string[] | null;
    customer?: string[] | null;
    customerType?: string[] | null;
    expectedVolume?: string[] | null;
    customerStatus?: string[] | null;
    marketDeadline?: string[] | null;
    customerDeadline?: string[] | null;
    stateTool?: string[] | null;
    IKAM?: string[] | null;
    comment?: string[] | null;
    supplyScenarios?: string[] | null;
    totalFleet?: string[] | null;
    countMarkets?: string[] | null;
    modifiedBy?: string[] | null;
    finishedDate?: string[] | null;
    jobDriver?: string[] | null;
}

export function defaultRequestListFilter(): RequestListFilter {
    return {
        requestId: null,
        typeOfRequest: null,
        customer: null,
        customerType: null,
        expectedVolume: null,
        customerStatus: null,
        marketDeadline: null,
        customerDeadline: null,
        stateTool: null,
        IKAM: null,
        comment: null,
        supplyScenarios: null,
        totalFleet: null,
        countMarkets: null,
        modifiedBy: null,
        finishedDate: null
    };
}
