import Vue from "vue";
import VueVirtualScroller from "vue-virtual-scroller";

import router from "./router";
import store from "./store";

import "./register-hooks";

import App from "./App.vue";

import "./assets/devextreme-theme/dx.generic.custom-scheme.css";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "flag-icons/css/flag-icons.css";
import "./assets/scss/index.scss";

Vue.use(VueVirtualScroller);

Vue.config.productionTip = true;

new Vue({
    store: store,
    router: router,
    render: (h) => h(App)
}).$mount("#app");
