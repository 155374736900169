export type RequestListButtonName =
    | "view"
    | "export"
    | "edit"
    | "finalize"
    | "copy"
    | "delete";

export function getDefaultRequestListButtonNames(): RequestListButtonName[] {
    return ["view"];
}
