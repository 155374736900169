
import Vue from "vue";
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import SideMenu from "@/components/SideMenu";
import MaintenanceBanner from "@/components/MaintenanceBanner";
import { maintenanceBannerModule } from "@/store/modules/maintenanceBanner";
import { MaintenanceBannerGetters } from "@/store/modules/maintenanceBanner/getters";
import Component from "vue-class-component";

@Component({
    components: {
        AppHeader,
        SideMenu,
        AppFooter,
        MaintenanceBanner
    },
    computed: {
        ...maintenanceBannerModule.mapGetters({
            isMaintenanceBannerShown:
                MaintenanceBannerGetters.IsMaintenanceBannerShown
        })
    }
})
export default class MainLayout extends Vue {
    protected readonly isMaintenanceBannerShown!: boolean;

    get mainClasses(): string[] {
        if (this.isMaintenanceBannerShown) {
            return ["include-maintenance-banner"];
        }
        return [];
    }
}
