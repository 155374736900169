import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { MutationTree } from "vuex";
import { MarketRequestCompareState } from ".";

export enum MarketRequestCompareMutations {
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    SetComparisonSettings = "setComparisonSettings",
    SetComparisonDetails = "setComparisonDetails",
    UpdateOriginalRequestSupplyScenario = "updateOriginalRequestSupplyScenario",
    UpdateComparedRequestSupplyScenario = "updateComparedRequestSupplyScenario"
}

const mutations: MutationTree<MarketRequestCompareState> = {
    [MarketRequestCompareMutations.SetErrors](state, payload: string[] | null) {
        state.errors = payload;
    },
    [MarketRequestCompareMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [MarketRequestCompareMutations.SetComparisonSettings](
        state,
        payload: RequestComparisonSettings | null
    ) {
        state.comparisonSettings = payload;
    },
    [MarketRequestCompareMutations.SetComparisonDetails](
        state,
        payload: ComparisonDetailsDTO | null
    ) {
        state.comparisonDetails = payload;
    },
    [MarketRequestCompareMutations.UpdateOriginalRequestSupplyScenario](
        state,
        payload: number
    ) {
        state.originalRequestSelectedSupplyScenario =
            state.comparisonDetails?.originalRequestSupplyScenarios.find(
                (details) => details.supplyScenarioId === payload
            ) ?? null;
    },
    [MarketRequestCompareMutations.UpdateComparedRequestSupplyScenario](
        state,
        payload: number
    ) {
        state.comparedRequestSelectedSupplyScenario =
            state.comparisonDetails?.comparedRequestSupplyScenarios.find(
                (details) => details.supplyScenarioId === payload
            ) ?? null;
    }
};

export default mutations;
