import roleApi from "@/api/roleApi";
import { RootState } from "@/store";
import { ActionTree } from "vuex";
import { RoleState } from ".";
import { RoleMutations } from "./mutations";

export enum RoleActions {
    LoadRoles = "loadRoles"
}

const actions: ActionTree<RoleState, RootState> = {
    async [RoleActions.LoadRoles]({ commit }) {
        commit(RoleMutations.SetLoading, true);
        try {
            const roles = await roleApi.getRoles();
            commit(RoleMutations.SetRoles, roles);
        } catch {
            commit(RoleMutations.SetErrors, ["Error"]);
        } finally {
            commit(RoleMutations.SetLoading, false);
        }
    }
};

export default actions;
