import modelApi from "@/api/modelApi";
import { RootState } from "@/store";
import { ActionTree } from "vuex";
import { ModelState } from ".";
import { ModelMutations } from "./mutations";

export enum ModelActions {
    LoadModels = "loadModels",
    LoadModelsInfo = "loadModelsInfo"
}

const actions: ActionTree<ModelState, RootState> = {
    async [ModelActions.LoadModels](context): Promise<void> {
        try {
            const models = await modelApi.getModels();
            context.commit(ModelMutations.SetModels, models);
        } catch {
            context.commit(ModelMutations.SetErrors, ["Errors"]);
        }
    },
    async [ModelActions.LoadModelsInfo](context) {
        try {
            const modelsInfo = await modelApi.getModelsInfo();
            context.commit(ModelMutations.SetInfo, modelsInfo);
        } catch {
            context.commit(ModelMutations.SetErrors, ["Errors"]);
        }
    }
};

export default actions;
