import { Market } from "@/models/market/Market";
import { MarketDetails } from "@/models/market/MarketDetails";
import { MarketInfo } from "@/models/market/MarketInfo";
import { MutationTree } from "vuex";
import { MarketState } from ".";

export enum MarketMutations {
    SetMarkets = "addMarkets",
    SetMarketDetails = "setMarketDetails",
    UpdateMarketDetails = "updateMarketDetails",
    RemoveMarket = "removeMarket",
    SetErrors = "setErrors",
    SetMarketInfo = "setMarketInfo",
    AddAttachment = "addAttachment",
    RemoveAttachment = "removeAttachment",
    ClearAttachments = "clearAttachments",
    Clear = "clear",
    SetLoading = "setLoading"
}

const mutations: MutationTree<MarketState> = {
    [MarketMutations.SetMarkets](state, payload: Market[]): void {
        state.markets = payload;
    },
    [MarketMutations.RemoveMarket](state, payload: number): void {
        state.markets = state.markets.filter((m) => m.marketId !== payload);
    },
    [MarketMutations.SetMarketDetails](state, payload: MarketDetails): void {
        state.marketDetails = payload;
        state.isEdited = false;
    },
    [MarketMutations.UpdateMarketDetails](
        state,
        payload: Partial<MarketDetails>
    ): void {
        if (state.marketDetails) {
            state.marketDetails = { ...state.marketDetails, ...payload };
            state.isEdited = true;
        }
    },
    [MarketMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [MarketMutations.SetMarketInfo](state, payload: MarketInfo): void {
        state.info = payload;
    },
    [MarketMutations.AddAttachment](state, payload: File): void {
        state.newAttachments.push(payload);
    },
    [MarketMutations.RemoveAttachment](state, payload: string | number): void {
        if (state.marketDetails) {
            const attachmentIndex = state.marketDetails.attachments.findIndex(
                (a) => a.id === payload
            );
            if (attachmentIndex > -1) {
                state.marketDetails.attachments.splice(attachmentIndex, 1);
                state.removedAttachmentIds.push(payload as number);
                return;
            }
        }
        const newAttachmentIndex = state.newAttachments.findIndex(
            (a) => a.name === payload
        );
        if (newAttachmentIndex > -1) {
            state.newAttachments.splice(newAttachmentIndex, 1);
        }
    },
    [MarketMutations.ClearAttachments](state): void {
        state.newAttachments = [];
        state.removedAttachmentIds = [];
    },
    [MarketMutations.Clear](state) {
        state.errors = null;
        state.newAttachments = [];
        state.removedAttachmentIds = [];
        state.isLoading = false;
        state.isEdited = false;
    },
    [MarketMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    }
};

export default mutations;
