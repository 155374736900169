import marketApi from "@/api/marketApi";
import { MarketDetails } from "@/models/market/MarketDetails";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { MarketState } from ".";
import { MarketMutations } from "./mutations";
import { saveData } from "@/services/saveData";
import { makeRequest, saveAttachment } from "@/services/requestUtils";

export enum MarketActions {
    LoadMarkets = "loadMarkets",
    LoadMarketDetails = "loadMarketDetails",
    LoadMarketInfo = "loadMarketInfo",
    DeleteMarket = "deleteMarket",
    AddNewMarket = "addNewMarket",
    CreateMarket = "createMarket",
    UpdateMarket = "updateMarket",
    ExportMarkets = "exportMarkets"
}

async function saveRequestAttachment(
    commit: Commit,
    requestId: number,
    attachments: File[],
    removeAttachmentsId: number[]
): Promise<void> {
    await saveAttachment(
        requestId,
        attachments,
        removeAttachmentsId,
        marketApi.uploadAttachment,
        marketApi.deleteAttachment,

        (attachment, errors) => {
            commit(
                MarketMutations.RemoveAttachment,
                Number.isInteger(attachment)
                    ? attachment
                    : (attachment as File).name
            );
            commit(MarketMutations.SetErrors, errors);
        }
    );
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        MarketMutations.SetErrors,
        MarketMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<MarketState, RootState> = {
    async [MarketActions.LoadMarkets]({ commit }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            const markets = await marketApi.getMarkets();
            commit(MarketMutations.SetMarkets, markets);
        });
    },
    async [MarketActions.LoadMarketDetails](
        { commit, dispatch },
        payload: number
    ): Promise<void> {
        commit(MarketMutations.Clear);
        dispatch(MarketActions.LoadMarketInfo);
        await makeModuleRequest(commit, async () => {
            commit(MarketMutations.SetMarketDetails, null);
            const marketDetails = await marketApi.getMarketDetails(payload);
            commit(MarketMutations.SetMarketDetails, marketDetails);
        });
    },
    async [MarketActions.LoadMarketInfo]({ commit }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            const info = await marketApi.getMarketInfo();
            commit(MarketMutations.SetMarketInfo, info);
        });
    },
    async [MarketActions.DeleteMarket](
        { commit },
        payload: number
    ): Promise<void> {
        await makeModuleRequest(commit, async () => {
            await marketApi.deleteMarket(payload);
            commit(MarketMutations.RemoveMarket, payload);
        });
    },
    [MarketActions.AddNewMarket](context): void {
        context.dispatch(MarketActions.LoadMarketInfo);

        const marketDetails: MarketDetails = {
            marketId: 0,
            name: "",
            marketTypeId: 0,
            regionId: 0,
            countryCodes: [],
            attachments: [],
            reminderEnabled: true
        };
        context.commit(MarketMutations.Clear);
        context.commit(MarketMutations.SetMarketDetails, marketDetails);
    },
    async [MarketActions.CreateMarket]({ commit, state }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            if (state.marketDetails) {
                const marketId = await marketApi.createMarket(
                    state.marketDetails
                );
                await saveRequestAttachment(
                    commit,
                    marketId,
                    state.newAttachments,
                    state.removedAttachmentIds
                );
                const marketDetails = await marketApi.getMarketDetails(
                    marketId
                );
                commit(MarketMutations.SetMarketDetails, marketDetails);
                commit(MarketMutations.ClearAttachments);
            }
        });
    },
    async [MarketActions.UpdateMarket]({ commit, state }): Promise<void> {
        await makeModuleRequest(commit, async () => {
            if (state.marketDetails) {
                await marketApi.updateMarket(state.marketDetails);
                await saveRequestAttachment(
                    commit,
                    state.marketDetails.marketId,
                    state.newAttachments,
                    state.removedAttachmentIds
                );
                const marketDetails = await marketApi.getMarketDetails(
                    state.marketDetails.marketId
                );
                commit(MarketMutations.SetMarketDetails, marketDetails);
                commit(MarketMutations.ClearAttachments);
            }
        });
    },
    async [MarketActions.ExportMarkets]({ commit }) {
        await makeModuleRequest(
            commit,
            () => marketApi.exportMarkets(),
            (file) => saveData(file.data, file.filename)
        );
    }
};

export default actions;
