import { Market } from "@/models/market/Market";
import { MarketDetails } from "@/models/market/MarketDetails";
import { MarketInfo } from "@/models/market/MarketInfo";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MarketState } from ".";

export enum MarketGetters {
    Markets = "markets",
    MarketDetails = "marketDetails",
    MarketsInfo = "marketInfo",
    IsLoading = "isLoading",
    Errors = "errors",
    IsEdited = "isEdited"
}

const getters: GetterTree<MarketState, RootState> = {
    [MarketGetters.Markets](state): Market[] {
        return state.markets;
    },
    [MarketGetters.MarketDetails](state): MarketDetails | null {
        return state.marketDetails;
    },
    [MarketGetters.MarketsInfo](state): MarketInfo {
        return state.info;
    },
    [MarketGetters.IsLoading](state): boolean {
        return state.isLoading;
    },
    [MarketGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [MarketGetters.IsEdited](state): boolean {
        return state.isEdited;
    }
};

export default getters;
