import { RequestListItem } from "@/models/request/list/RequestListItem";
import { Request } from "@/models/request/Request";
import { RequestInfo } from "@/models/request/RequestInfo";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";
import { defaultConfig } from "@/models/request/RequestGridConfig";
import localStorageService from "@/services/localStorageService";

export const REQUEST_COLUMN_SETTINGS = "request-column-settings";

// TODO: Vlad - Update after creating db structure and configure backend
export interface RequestState {
    isNew: boolean;
    info: RequestInfo;
    request: Request;
    requests: RequestListItem[];
    errors: string[] | null;
    isEdited: boolean;
    isLoading: boolean;
    formConfigTemplate: RequestFormConfig | null;
    newAttachments: File[];
    removedAttachmentIds: number[];
    requestGridConfig: RequestGridConfig;
}

const requestState: RequestState = {
    request: {
        requestTypeId: null,
        ikamId: null,
        customerId: null,
        supplyScenarios: [],
        customerDeadlineDate: new Date(),
        marketDeadlineDate: new Date(),
        marketRequests: [],
        requestFormConfig: null,
        requestVehicleModelFilter: null,
        questions: [],
        internationalBonus: null,
        attachments: []
    },
    info: {
        types: [],
        states: [],
        supplyScenarios: [],
        customerStatuses: [],
        marketDeadlineUniqueHeaderItems: [],
        customerDeadlineUniqueHeaderItems: [],
        finishedDateUniqueHeaderItems: []
    },
    formConfigTemplate: null,
    requests: [],
    isNew: true,
    errors: null,
    isEdited: false,
    isLoading: false,
    newAttachments: [],
    removedAttachmentIds: [],
    requestGridConfig:
        localStorageService.get(REQUEST_COLUMN_SETTINGS) ?? defaultConfig
};

const request: Module<RequestState, RootState> = {
    namespaced: true,
    state: requestState,
    mutations: mutations,
    getters: getters,
    actions: actions
};

export const requestModule = createNamespacedHelpers("request");

export default request;
