
import Vue from "vue";
import Component from "vue-class-component";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import Errors from "./BackendResponseErrors.json";
import { DxPopup, DxToolbarItem, DxPosition } from "devextreme-vue/popup";
import GenericPasswordChangeForm from "@/components/GenericPasswordChangeForm";
import notify from "devextreme/ui/notify";

@Component({
    components: {
        DxPopup,
        DxToolbarItem,
        DxPosition,
        GenericPasswordChangeForm
    },
    methods: {
        ...accountModule.mapActions({
            resetPassword: AccountActions.ResetPassword
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors
        })
    }
})
export default class PasswordResetForm extends Vue {
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly resetPassword!: (payload: {
        token: string;
        newPassword: string;
    }) => Promise<void>;
    protected token = "";
    popupVisible = false;

    closeButtonOptions = {
        type: "default",
        text: "Back to login",
        onClick: (): void => {
            this.$router.push({ name: "login" });
        }
    };

    mounted(): void {
        const queryToken = this.$route.query.token as string;
        if (queryToken == null || queryToken.length == 0) {
            notify(Errors.Default.Message, "Missing token.", 20000);
        } else {
            this.token = queryToken;
        }
    }

    async submit(password: string): Promise<void> {
        await this.resetPassword({
            token: this.$route.query.token as string,
            newPassword: password
        });

        const sessionErrors = this.getErrors();
        this.setErrors(null);

        if (sessionErrors !== undefined && sessionErrors !== null) {
            if (sessionErrors[0].includes(Errors.InvalidToken.Error)) {
                notify(Errors.InvalidToken.Message, "error", 10000);
            } else if (sessionErrors[0].includes(Errors.NetworkError.Error)) {
                notify(Errors.NetworkError.Message, "error", 10000);
            } else if (sessionErrors[0].includes(Errors.BadRequest.Error)) {
                notify(Errors.BadRequest.Message, "error", 10000);
            } else {
                notify(Errors.Default.Message, "error", 10000);
            }
        } else {
            this.popupVisible = true;
        }
    }
}
