import { Tip } from "@/models/Tip";
import api from ".";

async function getTips(userId: number): Promise<Tip[]> {
    return await api.get("tips/" + userId).then((r) => r.data);
}

async function deleteTip(tipId: number): Promise<void> {
    const response = await api.post("/tip/delete/" + tipId);
    return response.data;
}

export default {
    getTips,
    deleteTip
};
