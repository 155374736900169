import { User } from "@/models/User";
import store from "@/store";
import { AccountGetters } from "@/store/modules/account/getters";
import { roles } from "@/consts/roles";
import { MarketRequestState } from "@/models/request/MarketRequest";

export function getUserTitle(user: User, marketId: number): string {
    let baseTitle = `${user.role} | ${user.firstName} ${user.lastName}`;
    if (user.marketUsers?.length) {
        const marketInfo = user.marketUsers.find(
            (mi) => mi.marketId === marketId
        );
        if (marketInfo) {
            if (marketInfo.department) {
                baseTitle += ` | ${marketInfo.department}`;
            }
            if (marketInfo.position) {
                baseTitle += ` - ${marketInfo.position}`;
            }
        }
    }
    return baseTitle;
}

const roleClaim =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

const manageUserClaim = "BMW.TenderManagementTool.UserManage";
const manageMarketUserClaim = "BMW.TenderManagementTool.MarketUserManage";

export function isUserInRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        return (
            user.role === role ||
            (user.claims?.[roleClaim]?.includes(role) ?? false)
        );
    }
    return false;
}

export function isUserInRoles(roles: string[], user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        for (const role of roles) {
            if (isUserInRole(role, user)) {
                return true;
            }
        }
    }
    return false;
}

export function canUserManageRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user && user.claims) {
        return (
            user.claims?.[manageUserClaim]?.find((r) => r == role) !==
                undefined ||
            user.claims?.[manageMarketUserClaim]?.find((r) => r == role) !==
                undefined
        );
    }

    return false;
}

export function isUserAuthorized(): boolean {
    return getCurrentUser() !== null;
}

const roleToAllowedStatuses = {
    [roles.Admin]: [
        MarketRequestState.Open,
        MarketRequestState.UpdateRequested,
        MarketRequestState.Committed
    ],
    [roles.SU]: [
        MarketRequestState.Open,
        MarketRequestState.UpdateRequested,
        MarketRequestState.Committed
    ],
    [roles.MSU]: [MarketRequestState.Open, MarketRequestState.UpdateRequested],
    [roles.MU]: [MarketRequestState.Open, MarketRequestState.UpdateRequested]
};

export function allowImportValues(
    marketRequestStatus: MarketRequestState
): boolean {
    const userRole = getCurrentUser()?.role;

    // Directly return false if userRole is not present or not in the mapping
    if (!userRole || !(userRole in roleToAllowedStatuses)) return false;

    return roleToAllowedStatuses[userRole].includes(marketRequestStatus);
}

export function getFormattedUserName(user?: User | null): string {
    if (user) {
        return `${user.firstName} ${user.lastName}`;
    }
    return "";
}

function getCurrentUser(): User | undefined {
    return store.getters["account/" + AccountGetters.Session]?.User ?? null;
}
