export default class LoginFormData {
    email: string;
    password: string;
    twoFactorPassword: string;

    constructor() {
        this.email = "";
        this.password = "";
        this.twoFactorPassword = "";
    }
}
