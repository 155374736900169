import { LookupEntity } from "@/models/LookupEntity";

function StringIsNumber(value: string): boolean {
    return isNaN(Number(value)) === false;
}

// eslint-disable-next-line
export function enumToArray(e: any): LookupEntity[] {
    return Object.keys(e)
        .filter(StringIsNumber)
        .map((k) => ({
            id: Number(k),
            name: e[k]
        }));
}

// eslint-disable-next-line
export function stringEnumToArray(e: any): string[] {
    return Object.keys(e).map((k) => e[k]);
}
