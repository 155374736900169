export interface RequestListPagination {
    pageSize?: number;
    pageIndex?: number;
}

export function getDefaultRequestListPagination(): Required<RequestListPagination> {
    return {
        pageSize: 20,
        pageIndex: 0
    };
}
