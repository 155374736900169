import { MaintenanceBanner } from "@/models/MaintenanceBanner";
import { MutationTree } from "vuex";
import { MaintenanceBannerState } from ".";

export enum MaintenanceBannerMutations {
    SetMaintenanceBanner = "setMaintenanceBanner",
    ShowMaintenanceBanner = "showMaintenanceBanner"
}

const mutations: MutationTree<MaintenanceBannerState> = {
    [MaintenanceBannerMutations.SetMaintenanceBanner](
        state,
        payload: MaintenanceBanner
    ): void {
        state.maintenanceBanner = payload;
    },
    [MaintenanceBannerMutations.ShowMaintenanceBanner](
        state,
        payload: boolean
    ) {
        state.isMaintenanceBannerShown = payload;
    }
};

export default mutations;
