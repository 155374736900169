import { MarketRequest } from "./MarketRequest";
import { RequestFormConfig } from "./RequestFormConfig";
import { Question } from "../Question";
import { Attachment } from "@/models/Attachment";
import { RequestModelFilter } from "./RequestVehicleModelFilter";
import { Customer } from "../Customer";
import { User } from "../User";

export enum RequestState {
    New = "New",
    Active = "Active",
    Completed = "Completed"
}

export enum RequestType {
    DI = "DI",
    Tender = "Tender",
    RFI = "RFI"
}

export enum SupplyScenario {
    Sole = "Sole",
    Multi = "Multi",
    Dual = "Dual"
}

export enum RequestCustomerStatuses {
    DecisionPending = "Decision pending",
    PreviousVersion = "Previous version",
    Won = "WON",
    Lost = "LOST",
    SubmittedToCustomer = "Submitted to customer",
    Cancelled = "Cancelled"
}

// TODO: Vlad - Update after creating db structure and configure backend
export interface Request {
    requestId?: number;
    requestTypeId: number | null;
    ikamId: number | null;
    ikam?: User;
    customerId: number | null;
    customer?: Customer;
    marketRequests: MarketRequest[];
    supplyScenarios: number[];
    requestFormConfig: RequestFormConfig | null;
    requestVehicleModelFilter: RequestModelFilter | null;
    customerDeadlineDate: Date;
    marketDeadlineDate: Date;
    questions: Question[];
    internationalBonus: string | null;
    validityOfDiscountIndication?: string;
    additionalInformation?: string;
    expectedVolume?: string;
    adminComment?: string | null;
    totalFleet?: string | null;
    customerStatusId?: number | null;
    attachments: Attachment[];
}
