
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmptyItem,
    DxCompareRule,
    DxPatternRule,
    DxButtonItem,
    DxButtonOptions
} from "devextreme-vue/form";
import { KeyUpEvent } from "devextreme/ui/select_box";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmptyItem,
        DxCompareRule,
        DxPatternRule,
        DxButtonItem,
        DxButtonOptions
    },
    props: {
        buttonTitle: String
    }
})
export default class GenericPasswordChangeForm extends Vue {
    protected readonly passwordRequiredPattern =
        "^(?=.*[\"!$%&\\/()=?~{\\[\\]}\\\\@+\\-_#'<>|,;.:])(?=.*[a-zA-Z])(?=.*\\d).*$";

    protected readonly passwordAllowedPattern =
        "^[A-Za-z\\d!\"$%&\\/()=?~{\\[\\]}\\\\@+-_#'<>|,;.:]*$";

    protected readonly requiredCharacters =
        "Password must contain at least one number, one letter and one of these special character: " +
        " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

    protected readonly allowedSpecialCharacters =
        "Password may only contain roman letters, numbers and these special characters:" +
        " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

    private convertToChangeEvent(e: KeyUpEvent): void {
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        e.event?.target?.dispatchEvent(evt);
    }

    passwordOptions = {
        placeholder: "Your password",
        mode: "password",
        validationMessageMode: "always",
        onKeyUp: this.convertToChangeEvent
    };

    passwordConfirmOptions = {
        placeholder: "Confirm your password",
        mode: "password",
        validationMessageMode: "always",
        onKeyUp: this.convertToChangeEvent
    };

    passwordConfirmModel = {
        password: "",
        passwordConfirm: ""
    };

    passwordComparison(): string {
        return this.passwordConfirmModel.password;
    }

    async submit(): Promise<void> {
        this.$emit("update:password", this.passwordConfirmModel.password);
    }
}
