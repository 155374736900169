
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxDateBox } from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxButton } from "devextreme-vue/button";
import { MaintenanceBanner } from "@/models/MaintenanceBanner";
import notify from "devextreme/ui/notify";
import { AxiosResponse } from "axios";
import { getValidationErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { maintenanceBannerModule } from "@/store/modules/maintenanceBanner";
import { MaintenanceBannerActions } from "@/store/modules/maintenanceBanner/actions";
import DxCheckBox from "devextreme-vue/check-box";
import { correctTimeWithOffset } from "@/services/correctTimeWithOffset";
import { MaintenanceBannerGetters } from "@/store/modules/maintenanceBanner/getters";

@Component({
    components: {
        DxPopup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxTextArea,
        DxButton,
        DxCheckBox
    },
    methods: {
        ...maintenanceBannerModule.mapActions({
            loadMaintenanceBanner:
                MaintenanceBannerActions.LoadMaintenanceBanner,
            createMaintenanceBanner:
                MaintenanceBannerActions.CreateMaintenanceBanner
        })
    },
    computed: {
        ...maintenanceBannerModule.mapGetters({
            maintenanceBanner: MaintenanceBannerGetters.MaintenanceBanner
        })
    }
})
export default class CreateMaintenanceBannerPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    private readonly loadMaintenanceBanner!: () => Promise<void>;
    private readonly createMaintenanceBanner!: (
        banner: MaintenanceBanner
    ) => Promise<void>;

    protected width = 800;
    protected height = 570;

    private readonly maintenanceBanner!: MaintenanceBanner | null;

    protected bannerContent: MaintenanceBanner = {
        message: "",
        fromDateTime: new Date(),
        toDateTime: new Date(),
        isVisible: false
    };

    protected minDate: Date = new Date(new Date(Date.now()));

    @Watch("visible")
    async visibleChanged(): Promise<void> {
        await this.loadMaintenanceBanner();

        if (this.maintenanceBanner) {
            this.bannerContent = { ...this.maintenanceBanner };
        }
    }

    get isMaintenanceBannerVisible(): boolean {
        return this.bannerContent?.isVisible ?? false;
    }
    set isMaintenanceBannerVisible(value: boolean) {
        this.bannerContent.isVisible = value;
    }

    async submit(): Promise<void> {
        try {
            this.bannerContent.fromDateTime = correctTimeWithOffset(
                new Date(this.bannerContent.fromDateTime)
            );
            this.bannerContent.toDateTime = correctTimeWithOffset(
                new Date(this.bannerContent.toDateTime)
            );
            this.createMaintenanceBanner(this.bannerContent);
            notify("Success", "success", 1000);
            this.popupVisible = false;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const response: AxiosResponse = error.response;

            let errorMessage: string;
            if (response) {
                errorMessage =
                    getValidationErrorTextsFromErrorResponse(error).join(";");
            } else {
                errorMessage = "Network error!";
            }

            notify(`Error! ${errorMessage}`, "error", 3000);
        } finally {
            await this.loadMaintenanceBanner();
        }
    }
}
