import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import modules from "./modules";

Vue.use(Vuex);

// eslint rule is disabled while root state is empty
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

const rootState: StoreOptions<RootState> = {
    state: {},
    modules: modules,
    strict: process.env.NODE_ENV === "development"
};

export default new Vuex.Store(rootState);
