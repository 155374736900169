
import logo from "@/assets/img/BMW_logo_(white).svg";
import RequestPasswordResetForm from "@/components/RequestPasswordResetForm";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
    components: { RequestPasswordResetForm }
})
export default class RequestPasswordReset extends Vue {
    private logo: string = logo;
}
