export interface ModelGridConfig {
    brand: boolean;
    series: boolean;
    eSeries: boolean;
    fuelType: boolean;
    hybridFlag: boolean;
    code: boolean;
    modelName: boolean;
    bodyType: boolean;
    engineSize: boolean;
    enginePowerHP: boolean;
    enginePowerKW: boolean;
    transmissionType: boolean;
    xWD: boolean;
    co2: boolean;
    electricRange: boolean;
    fuelConsumption: boolean;
    electricityConsumption: boolean;
    price: boolean;

    vehicleDiscountValue: boolean;
    optionDiscount: boolean;
}

export function defaultModelGridConfig(): ModelGridConfig {
    return {
        brand: true,
        series: true,
        eSeries: true,
        code: true,
        fuelType: true,
        hybridFlag: true,
        modelName: true,
        bodyType: false,
        engineSize: false,
        enginePowerHP: false,
        enginePowerKW: false,
        transmissionType: false,
        xWD: false,
        co2: false,
        electricRange: false,
        fuelConsumption: false,
        electricityConsumption: false,
        price: false,

        vehicleDiscountValue: true,
        optionDiscount: true
    };
}
