
import logo from "@/assets/img/double_logo_BMW_MINI.png";
import Vue from "vue";
import Component from "vue-class-component";
import UserBadge from "../UserBadge";

@Component({
    components: { UserBadge }
})
export default class AppHeader extends Vue {
    logo: string = logo;
}
