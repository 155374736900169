export type RequestListColumnName =
    | "requestId"
    | "requestType"
    | "customer"
    | "customerType"
    | "expectedVolume"
    | "customerStatus"
    | "marketDeadline"
    | "customerDeadline"
    | "state"
    | "IKAM"
    | "progress"
    | "createdBy"
    | "modifiedBy"
    | "finishedDate"
    | "finishedBy"
    | "adminComment"
    | "supplyScenarios"
    | "totalFleet"
    | "totalMarkets"
    | "details"
    | "jobDriver";

export function getDefaultRequestListColumnNames(): RequestListColumnName[] {
    return ["requestId", "requestType", "customer"];
}
