import { MutationTree } from "vuex";
import { RoleState } from ".";

export enum RoleMutations {
    SetRoles = "setRoles",
    SetErrors = "setErrors",
    SetLoading = "setLoading"
}

const mutations: MutationTree<RoleState> = {
    [RoleMutations.SetRoles](state, payload: string[]): void {
        state.roles = payload;
    },
    [RoleMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [RoleMutations.SetLoading](state, payload: boolean): void {
        if (payload) {
            state.roles = [];
            state.errors = null;
        }
        state.loading = payload;
    }
};

export default mutations;
