
import logo from "@/assets/img/BMW_logo_(white).svg";
import Component from "vue-class-component";
import AccountActivationForm from "@/components/AccountActivationForm";
import Vue from "vue";

@Component({
    components: { AccountActivationForm }
})
export default class AccountActivation extends Vue {
    private logo: string = logo;
}
