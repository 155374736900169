
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxButton,
    DxEditing,
    DxPaging,
    DxPager,
    DxFilterRow,
    DxHeaderFilter,
    DxLoadPanel,
    DxRemoteOperations
} from "devextreme-vue/data-grid";
import RequestProgressBar from "@/components/RequestProgressBar";
import { stringEnumToArray } from "@/services/enumUtils";
import {
    RequestState,
    RequestType,
    SupplyScenario
} from "@/models/request/Request";
import dxDataGrid, {
    ColumnButtonClickEvent,
    EditingStartEvent,
    SavedEvent
} from "devextreme/ui/data_grid";
import { User } from "@/models/User";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestInfo, YearItem } from "@/models/request/RequestInfo";
import { RequestAdminDataUpdateDTO } from "@/models/request/RequestAdminDataUpdateDTO";
import { RequestAdminDetailsUpdateDTO } from "@/models/request/RequestAdminDetailsUpdateDTO";
import { RequestFilterName } from "@/models/request/list/RequestListFilterName";
import {
    RequestListColumnName,
    getDefaultRequestListColumnNames
} from "@/models/request/list/RequestListColumnName";
import {
    getDefaultRequestListButtonNames,
    RequestListButtonName
} from "@/models/request/list/RequestListButtonName";
import {
    RequestListOptions,
    getDefaultRequestListOptions
} from "@/models/request/list/RequestListOptions";
import CustomStore from "devextreme/data/custom_store";
import { customerModule } from "@/store/modules/customer";
import { CustomerGetters } from "@/store/modules/customer/getters";
import { CustomerActions } from "@/store/modules/customer/actions";
import { Customer } from "@/models/Customer";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { UserActions } from "@/store/modules/user/actions";
import { RequestCustomerStatuses } from "@/models/request/Request";
import { getFormattedUserName } from "@/services/userUtils";
import { LookupEntity } from "@/models/LookupEntity";
import { isUserInRoles } from "@/services/userUtils";

type FilterType = "include" | "exclude" | "contain";

@Component({
    components: {
        DxDataGrid,
        DxColumn,
        DxLookup,
        DxButton,
        DxEditing,
        DxPager,
        DxPaging,
        DxFilterRow,
        DxHeaderFilter,
        DxRemoteOperations,
        RequestProgressBar,
        DxLoadPanel
    },
    computed: {
        ...requestModule.mapGetters({
            info: RequestGetters.Info
        }),
        ...customerModule.mapGetters({
            customers: CustomerGetters.Customers
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users,
            ikams: UserGetters.Ikams,
            adminsAndSU: UserGetters.AdminsAndSU
        })
    },
    methods: {
        ...requestModule.mapActions({
            loadInfo: RequestActions.LoadInfo
        }),
        ...customerModule.mapActions({
            loadCustomers: CustomerActions.LoadCustomers
        }),
        ...userModule.mapActions({
            loadUsers: UserActions.LoadUsers,
            loadIKAMs: UserActions.LoadIKAMs
        })
    }
})
export default class RequestList extends Vue {
    private readonly loadInfo!: () => Promise<void>;
    private readonly loadCustomers!: () => Promise<void>;
    private readonly loadUsers!: () => Promise<void>;
    private readonly loadIKAMs!: () => Promise<void>;
    private readonly getFormattedUserName = getFormattedUserName;

    private readonly info!: RequestInfo | null;
    protected readonly customers!: Customer[] | null;
    protected readonly users!: User[] | null;
    protected readonly ikams!: User[] | null;
    protected readonly adminsAndSU!: LookupEntity[] | null;

    // For backward-compatibility to support plain arrays
    @Prop({ type: Array, required: false })
    public readonly requests!: RequestListItem[];

    @Prop({ type: Boolean, default: false })
    public readonly allowEditingAdminData!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly allowEditingAdminDetails!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    @Prop({ default: undefined })
    public readonly height!: number | string | undefined;

    @Prop({ type: Object, default: getDefaultRequestListOptions })
    public options!: RequestListOptions;

    @Prop({ type: Array, default: getDefaultRequestListColumnNames })
    public columns!: RequestListColumnName[];

    @Prop({ type: Array, default: getDefaultRequestListButtonNames })
    public buttons!: RequestListButtonName[];

    @Prop({ type: Object, default: null })
    public readonly dataSource!: CustomStore;

    protected readonly types = stringEnumToArray(RequestType);

    protected readonly requestStateDataSource = [
        { value: RequestState.New, text: "Draft" },
        { value: RequestState.Active, text: "In Progress" },
        { value: RequestState.Completed, text: "Archived" }
    ];
    protected readonly supplyScenariosDataSource = [
        { value: SupplyScenario.Sole, text: "Sole" },
        { value: SupplyScenario.Dual, text: "Dual" },
        { value: SupplyScenario.Multi, text: "Multi" }
    ];

    protected readonly filterTypes: {
        [K in RequestFilterName]: FilterType;
    } = {
        requestId: "include",
        typeOfRequest: "include",
        customer: "include",
        customerType: "include",
        expectedVolume: "include",
        customerStatus: "include",
        marketDeadline: "include",
        customerDeadline: "include",
        stateTool: "include",
        IKAM: "include",
        comment: "include",
        supplyScenarios: "contain",
        totalFleet: "include",
        countMarkets: "include",
        modifiedBy: "include",
        finishedDate: "include"
    };

    public refresh(): void {
        this.dataGrid?.refresh();
    }

    public customerStatusesForSendingMail: string[] = [
        RequestCustomerStatuses.Won,
        RequestCustomerStatuses.Lost,
        RequestCustomerStatuses.Cancelled
    ];

    created(): void {
        // Load all necessary data to display in the header filters
        // (not all data, only that necessary to filter despite the fact if there are records with these values on the current page or not
        //  for other fields they are going to be calculated on local request values)
        this.loadInfo();
        this.loadCustomers();
        this.loadUsers();
        this.loadIKAMs();
    }

    mounted(): void {
        if (this.loading) {
            this.dataGrid?.beginCustomLoading("Loading...");
        }
    }

    viewButtonClick(e: ColumnButtonClickEvent<RequestListItem, number>): void {
        if (e.row?.key) {
            this.$emit("click-on-request", e.row.key);
        }
    }

    exportButtonClick(
        e: ColumnButtonClickEvent<RequestListItem, number>
    ): void {
        if (e.row?.key) {
            this.$emit("export-button-click", e.row.key);
        }
    }

    editButtonClick(e: ColumnButtonClickEvent<RequestListItem, number>): void {
        if (e.row?.key) {
            this.$emit("edit-button-click", e.row.key);
        }
    }

    finalizeButtonClick(
        e: ColumnButtonClickEvent<RequestListItem, number>
    ): void {
        if (e.row?.key) {
            this.$emit("finalize-button-click", e.row.key);
        }
    }

    copyButtonClick(e: ColumnButtonClickEvent<RequestListItem, number>): void {
        if (e.row?.key) {
            this.$emit("copy-button-click", e.row.key);
        }
    }

    deleteButtonClick(
        e: ColumnButtonClickEvent<RequestListItem, number>
    ): void {
        if (e.row?.key) {
            this.$emit("delete-button-click", e.row.key);
        }
    }

    statusFormat(count: number, total: number): (value: number) => string {
        return (value: number) =>
            `Progress: ${value * 100}% Count: ${count}/${total}`;
    }

    marketDeadlineDateValue(rowData: RequestListItem): Date {
        return new Date(rowData.marketDeadlineDate);
    }

    customerDeadlineDateValue(rowData: RequestListItem): Date {
        return new Date(rowData.customerDeadlineDate);
    }

    finishedDateTimeValue(rowData: RequestListItem): Date | null {
        if (rowData.finishedDateTime) {
            return new Date(rowData.finishedDateTime);
        }
        return null;
    }

    getStateName(rowData: RequestListItem): string {
        const state = this.requestStateDataSource.find(
            (s) => s.value === rowData.state
        );
        if (state) {
            return state.text;
        }
        return rowData.state;
    }

    public reloadData(): void {
        if (this.dataGrid) {
            // Accessing the DataGrid instance
            const dataGridInstance = this.dataGrid;
            // Reload data
            if (dataGridInstance && dataGridInstance.getDataSource()) {
                dataGridInstance.getDataSource().reload();
            }
        }
    }

    $refs!: Vue["$refs"] & {
        datagrid: DxDataGrid;
    };

    @Watch("loading")
    loadingChanged(newVal: boolean): void {
        // We need to refresh datagrid to update button column states, because disabled properties update on refreshing only
        // Only if the component uses plain array as a data-source, otherwise it can cause multiple loading data
        if (!this.dataSource) {
            this.dataGrid?.refresh();
        }

        // We should use custom loading only with plain array, the load panel works well with custom data-source
        if (!this.dataSource) {
            if (newVal) {
                this.dataGrid?.beginCustomLoading("Loading...");
            } else {
                this.dataGrid?.endCustomLoading();
            }
        }
    }

    get adminsAndSUWithFullName(): unknown[] {
        const users = this.adminsAndSU ?? [];
        users.unshift({ id: 0, name: "unassigned" });
        return users;
    }

    get customerStatuses(): unknown[] {
        return this.info?.customerStatuses ?? [];
    }

    get usersDataSource(): unknown[] {
        return (
            this.users?.map((u) => ({
                value: u.id,
                text: getFormattedUserName(u)
            })) ?? []
        );
    }

    get ikamsDataSource(): unknown[] {
        return (
            this.ikams?.map((u) => ({
                value: u.id,
                text: getFormattedUserName(u)
            })) ?? []
        );
    }

    get dataGrid(): dxDataGrid<RequestListItem, number> | undefined {
        return this.$refs.datagrid.instance;
    }

    get marketDeadlineDates(): YearItem[] | undefined {
        return this.info?.marketDeadlineUniqueHeaderItems;
    }
    get customerDeadlineDates(): YearItem[] | undefined {
        return this.info?.customerDeadlineUniqueHeaderItems;
    }
    get finishedDates(): YearItem[] | undefined {
        return this.info?.finishedDateUniqueHeaderItems;
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles(["Admin", "SU"]);
    }

    // Allow to edit only if it's allowed to edit admin data and if a request is in Active or Completed statuses
    onEditingStart(e: EditingStartEvent<RequestListItem>): void {
        e.cancel = !(
            (this.allowEditingAdminData &&
                [RequestState.Active, RequestState.Completed].includes(
                    e.data.state
                )) ||
            this.allowEditingAdminDetails
        );
    }

    saved(e: SavedEvent<RequestListItem>): void {
        e.changes.forEach((c) => {
            if (this.allowEditingAdminDetails) {
                const dto: RequestAdminDetailsUpdateDTO = {
                    requestId: c.key,
                    details: c.data.details,
                    jobDriverId: c.data.jobDriverId
                };
                this.$emit("admin-details-updated", dto);
            } else {
                const dto: RequestAdminDataUpdateDTO = {
                    requestId: c.key,
                    customerStatusId: c.data.customerStatusId,
                    adminComment: c.data.adminComment
                };
                this.sendChangeStatusMessages(c.data.customerStatusId, c.key);
                this.$emit("admin-data-updated", dto);
            }
        });
    }

    sendChangeStatusMessages(
        statusId: number | null | undefined,
        requestId: number
    ): void {
        if (!statusId) return;
        const statuses = this.customerStatuses as Array<{
            requestCustomerStatusId: number;
            name: string;
        }>;
        const status = statuses.find(
            (status) => status.requestCustomerStatusId === statusId
        );
        if (status && this.isStatusInEnum(status.name)) {
            const customerStatusChangedEmail = {
                requestId: requestId,
                requestCustomerStatusName: status.name,
                message: ""
            };
            this.$emit("request-status-changed", customerStatusChangedEmail);
        }
    }

    isStatusInEnum(name: string): boolean {
        return this.customerStatusesForSendingMail.includes(name);
    }
}
