
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { DxPopup } from "devextreme-vue/popup";
import { DxCheckBox } from "devextreme-vue/check-box";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestMutations } from "@/store/modules/request/mutations";
import { DxScrollView } from "devextreme-vue";
import { isUserInRoles } from "@/services/userUtils";

@Component({
    components: { DxButton, DxPopup, DxCheckBox, DxScrollView },
    computed: {
        ...requestModule.mapGetters({
            config: RequestGetters.RequestGridConfig
        })
    },
    methods: {
        ...requestModule.mapMutations({
            updateConfig: RequestMutations.UpdateRequestGridConfig
        })
    }
})
export default class RequestColumnSettings extends Vue {
    protected readonly config!: RequestGridConfig;

    protected popupVisible = false;

    private readonly updateConfig!: (
        fields: Partial<RequestGridConfig>
    ) => void;

    showPopup(): void {
        this.popupVisible = true;
    }

    updateField(fieldName: string, value: boolean): void {
        this.updateConfig({ [fieldName]: value });
    }

    get isUserHQ(): boolean {
        return isUserInRoles(["Admin", "SU", "HV"]);
    }
}
