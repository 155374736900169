import { LookupEntity } from "@/models/LookupEntity";
import { User } from "@/models/User";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { UserState } from ".";
import { roles } from "@/consts/roles";

export enum UserGetters {
    Users = "users",
    LookupUsers = "lookupUsers",
    Ikams = "ikams",
    LookupIkams = "lookupIkams",
    User = "user",
    Errors = "errors",
    Loading = "loading",
    IsEdited = "isEdited",
    AdminsAndSU = "adminsAndSU"
}

const getters: GetterTree<UserState, RootState> = {
    [UserGetters.Users](state): User[] {
        return state.users;
    },
    [UserGetters.User](state): Partial<User> | null {
        return state.user;
    },
    [UserGetters.Loading](state): boolean {
        return state.loading;
    },
    [UserGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [UserGetters.IsEdited](state): boolean {
        return state.isEdited;
    },
    [UserGetters.LookupUsers](state): LookupEntity[] {
        return state.users.map((u) => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`
        }));
    },
    [UserGetters.Ikams](state): User[] {
        return state.ikams;
    },
    [UserGetters.LookupIkams](state): LookupEntity[] {
        return state.ikams.map((u) => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`
        }));
    },
    [UserGetters.AdminsAndSU](state): LookupEntity[] {
        return state.users
            .filter((u) => u.role === roles.Admin || u.role === roles.SU)
            .map((u) => ({
                id: u.id,
                name: `${u.firstName} ${u.lastName}`
            }));
    }
};

export default getters;
