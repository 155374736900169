import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MarketRequestCompareState } from ".";
import { SupplyScenario } from "@/models/request/SupplyScenario";

export enum MarketRequestCompareGetters {
    IsLoading = "isLoading",
    Errors = "errors",
    ComparisonSettings = "comparisonSettings",
    ComparisonDetails = "comparisonDetails",
    OriginalRequestSelectedSupplyScenario = "originalRequestSelectedSupplyScenario: null",
    ComparedRequestSelectedSupplyScenario = "comparedRequestSelectedSupplyScenario: null"
}

const getters: GetterTree<MarketRequestCompareState, RootState> = {
    [MarketRequestCompareGetters.IsLoading](state): boolean {
        return state.isLoading;
    },
    [MarketRequestCompareGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [MarketRequestCompareGetters.ComparisonSettings](
        state
    ): RequestComparisonSettings | null {
        return state.comparisonSettings;
    },
    [MarketRequestCompareGetters.ComparisonDetails](
        state
    ): ComparisonDetailsDTO | null {
        return state.comparisonDetails;
    },
    [MarketRequestCompareGetters.OriginalRequestSelectedSupplyScenario](
        state
    ): SupplyScenario | null {
        return state.originalRequestSelectedSupplyScenario;
    },
    [MarketRequestCompareGetters.ComparedRequestSelectedSupplyScenario](
        state
    ): SupplyScenario | null {
        return state.comparedRequestSelectedSupplyScenario;
    }
};

export default getters;
