import { User } from "@/models/User";
import api from ".";

function getUserByMarket(marketId: number): Promise<User[]> {
    return api.get(`markets/${marketId}/users`).then((r) => r.data);
}

async function getUsers(): Promise<User[]> {
    const response = await api.get("/users");
    return response.data;
}

async function getUserDetails(userId: number): Promise<User> {
    const response = await api.get("/user/" + userId);
    return response.data;
}

async function getIKAMS(): Promise<User[]> {
    const response = await api.get("/users/ikams");
    return response.data;
}

async function updateUser(user: Partial<User>): Promise<void> {
    const response = await api.post("/user/" + user.id, user);
    return response.data;
}

async function deleteUser(userId: number): Promise<void> {
    await api.delete("/user/" + userId);
}

async function createUser(user: Partial<User>): Promise<void> {
    const response = await api.post("/users/", user);
    return response.data;
}

async function getTwoFactorRecoveryCode(userId: number): Promise<string> {
    const response = await api.get(`/user/${userId}/code`);
    if (response.status != 200) throw response.data;
    return response.data;
}

async function exportUsers(): Promise<{ filename: string; data: Blob }> {
    return api
        .post(`users/export`, null, { responseType: "blob" })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename="(.+)"/)[1]
            };
        });
}

export default {
    getUserByMarket,
    getIKAMS,
    getUsers,
    getUserDetails,
    updateUser,
    deleteUser,
    createUser,
    exportUsers,
    getTwoFactorRecoveryCode
};
