import { render, staticRenderFns } from "./AccountActivationForm.html?vue&type=template&id=a511ec0e&scoped=true&"
import script from "./AccountActivationForm.vue?vue&type=script&lang=ts&"
export * from "./AccountActivationForm.vue?vue&type=script&lang=ts&"
import style0 from "./AccountActivationForm.scss?vue&type=style&index=0&id=a511ec0e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a511ec0e",
  null
  
)

export default component.exports