import tipApi from "@/api/tipApi";
import { RootState } from "@/store";
import { ActionTree } from "vuex";
import { TipState } from ".";
import { TipMutations } from "./mutations";

export enum TipActions {
    LoadTips = "loadTips",
    DeleteTip = "deleteTip"
}

const actions: ActionTree<TipState, RootState> = {
    async [TipActions.LoadTips]({ commit }, payload: number) {
        commit(TipMutations.SetLoading, true);
        try {
            const tips = await tipApi.getTips(payload);
            commit(TipMutations.SetTips, tips);
        } catch {
            commit(TipMutations.SetErrors, ["Error"]);
        } finally {
            commit(TipMutations.SetLoading, false);
        }
    },
    async [TipActions.DeleteTip]({ commit }, payload: number) {
        await tipApi.deleteTip(payload);
        commit(TipMutations.UpdateTip, payload);
    }
};

export default actions;
