import { VehicleModel } from "@/models/VehicleModel";
import { ModelsInfo } from "@/models/ModelsInfo";
import { MutationTree } from "vuex";
import { ModelState } from ".";

export enum ModelMutations {
    SetModels = "setModels",
    SetErrors = "setErrors",
    SetInfo = "setInfo",
    UpdateInfo = "updateInfo"
}

const mutations: MutationTree<ModelState> = {
    [ModelMutations.SetModels](state, payload: VehicleModel[]) {
        state.models = payload;
    },
    [ModelMutations.SetErrors](state, payload: string[] | null) {
        state.errors = payload;
    },
    [ModelMutations.SetInfo](state, payload: ModelsInfo) {
        state.info = payload;
    },
    [ModelMutations.UpdateInfo](state, payload: Partial<ModelsInfo>) {
        if (state.info) {
            state.info = { ...state.info, ...payload };
        }
    }
};

export default mutations;
