import { AxiosError } from "axios";
import { ValidationReponseData } from "@/models/ValidationResponseData";

export function getValidationErrorTextsFromErrorResponse(
    error: Error
): Array<string> {
    const axiosError = error as AxiosError;
    if (axiosError.response === undefined) return [error.message];
    if (axiosError.response.data === undefined)
        return [axiosError.response.status.toString()];

    const validationData = axiosError.response.data as ValidationReponseData;

    if (validationData.errors !== undefined) {
        return Object.entries(validationData.errors).map((error) => {
            return `validation-problem -> property: ${error[0]} message: ${error[1]}`;
        });
    }

    return [axiosError.response.status.toString(), axiosError.response.data];
}

export function isValidationError(error: string): boolean {
    return error.includes("validation-problem -> property:");
}

export function formatValidationErrors(errors: string[]): string {
    return errors
        .map((error) => {
            return error.substr(error.search("message:") + 8);
        })
        .join(" ");
}

export function getErrorMessageFromArray(errorArray: string[]): string {
    if (typeof errorArray === "string") {
        return errorArray;
    } else {
        const errors = errorArray.map(
            (error: string | Record<string, unknown>) => {
                if (typeof error === "object" && error !== null) {
                    return (error as { title: string }).title;
                } else if (typeof error === "string") {
                    return error;
                } else {
                    return "";
                }
            }
        );
        return errors.join(". ");
    }
}
