import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import api from ".";

function getCompareSettingsByMarketRequestId(
    marketRequestId: number
): Promise<RequestComparisonSettings> {
    return api
        .get<RequestComparisonSettings>(
            `request/market-request/${marketRequestId}/compare/settings`
        )
        .then((r) => r.data);
}

function getComparisonDetails(
    originalMarketRequestId: number,
    comparedMarketRequestId: number
): Promise<ComparisonDetailsDTO> {
    return api
        .get<ComparisonDetailsDTO>("request/compare/details", {
            params: { originalMarketRequestId, comparedMarketRequestId }
        })
        .then((r) => r.data);
}

function createComparisonSettings(
    comparisonSettings: RequestComparisonSettings
): Promise<void> {
    return api.post("request/compare", comparisonSettings);
}

function updateComparisonSettings(
    comparisonSettings: RequestComparisonSettings
): Promise<void> {
    return api.put(
        `request/compare/${comparisonSettings.requestComparisonSettingsId}`,
        comparisonSettings
    );
}

function deleteComparisonSettings(comparisonSettingsId: number): Promise<void> {
    return api.delete(`request/compare/${comparisonSettingsId}`);
}

function exportComparison(
    marketRequestId: number
): Promise<{ filename: string; data: Blob }> {
    return api
        .post(`request/compare/${marketRequestId}/export`, null, {
            responseType: "blob"
        })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename="(.+)"/)[1]
            };
        });
}

export default {
    getCompareSettingsByMarketRequestId,
    getComparisonDetails,
    createComparisonSettings,
    updateComparisonSettings,
    deleteComparisonSettings,
    exportComparison
};
