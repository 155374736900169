import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { TipState } from ".";
import { Tip } from "@/models/Tip";

export enum TipGetters {
    Tips = "tips",
    RandomTip = "randomTip",
    Errors = "errors",
    Loading = "loading"
}

const getters: GetterTree<TipState, RootState> = {
    [TipGetters.Tips](state): Tip[] {
        return state.tips;
    },
    [TipGetters.Loading](state): boolean {
        return state.loading;
    },
    [TipGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [TipGetters.RandomTip](state): Tip | null {
        if (state.tips) {
            const randomIndex = Math.floor(Math.random() * state.tips.length);
            const randTip = state.tips[randomIndex];
            if (randTip) return randTip;
        }
        return null;
    }
};

export default getters;
