
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { DxPopup } from "devextreme-vue/popup";
import { DxDateBox } from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxButton } from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import { AxiosResponse } from "axios";
import {
    getErrorMessageFromArray,
    getValidationErrorTextsFromErrorResponse
} from "@/services/responseErrorUtils";
import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";
import marketRequestApi from "@/api/marketRequestApi";
import { CustomerStatusChangedEmail } from "@/models/request/CustomerStatusChangedEmail";

@Component({
    components: {
        DxPopup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxTextArea,
        DxFileUploader,
        DxButton,
        DxHtmlEditor,
        DxToolbar,
        DxItem
    }
})
export default class RequestStatusChangedPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @PropSync("customerStatusChangedEmail", { type: Object, required: true })
    public readonly email!: CustomerStatusChangedEmail;

    protected width = 1200;
    protected height = 770;
    protected sizeValues = [
        "8pt",
        "10pt",
        "12pt",
        "14pt",
        "18pt",
        "24pt",
        "36pt"
    ];
    protected fontValues = [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana"
    ];
    protected headerValues = [false, 1, 2, 3, 4, 5];
    public selectedItems = [{ text: "Html" }];
    get editorValueType(): unknown {
        return this.selectedItems[0].text.toLowerCase();
    }

    protected readonly maxFileSize = 30 * 1000 * 1000; // in bytes => 30MB

    public uploaderKey = 0;
    get title(): string {
        return `“Customer Status” for the request with Job-ID ${this.email.requestId} has changed to ${this.email.requestCustomerStatusName} - please provide some text (and optionally attachments) for a corresponding notification email to relevant markets, the IKAM and the TMT responsible`;
    }

    closePopup(): void {
        this.resetUploader();
        this.popupVisible = false;
    }

    // We did not find in the documentation a correct way to reset uploader contents, so due to limited time for implementation we decided to leave this method.
    resetUploader(): void {
        this.uploaderKey++;
    }

    @Watch("visible")
    visibleChanged(): void {
        this.email.message = "";
        this.email.attachment = undefined;
    }

    uploadFile(file: File): void {
        if (file.size < this.maxFileSize) {
            this.email.attachment = file;
        } else {
            this.email.attachment = undefined;
            notify("The maximum file size allowed is 30MB", "warning", 3000);
            this.resetUploader();
        }
    }
    async submit(): Promise<void> {
        try {
            this.popupVisible = false;
            const response =
                await marketRequestApi.sendRequestStatusChangedMessage(
                    this.email
                );
            if (response.data.success) {
                notify(response.data.message, "success", 5000);
            } else {
                if (response.data.errors) {
                    const stringErrors = getErrorMessageFromArray(
                        response.data.errors as string[]
                    );

                    notify(
                        `Customer Status change: ${stringErrors}`,
                        "error",
                        10000
                    );
                } else {
                    notify("An unknown error occurred.", "error", 10000);
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            const response: AxiosResponse = error.response;

            let errorMessage: string;
            if (response) {
                errorMessage =
                    getValidationErrorTextsFromErrorResponse(error).join(";");
            } else {
                errorMessage = "Network error!";
            }

            notify(`Error! ${errorMessage}`, "error", 3000);
        }
    }
}
