
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule,
    DxEmptyItem,
    DxButtonItem,
    DxPatternRule,
    DxGroupItem
} from "devextreme-vue/form";
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import LoginFormData from "@/models/LoginFormData";

@Component({
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule,
        DxEmptyItem,
        DxButtonItem,
        DxPatternRule,
        DxGroupItem
    },
    props: {
        loginData: LoginFormData
    }
})
export default class LoginForm extends Vue {
    protected passwordResetRoute = Routes.RequestPasswordReset;

    twoFactorPasswordOptions = {
        placeholder: "Enter code",
        width: 200
    };
    twoFactorButtonOptions = {
        text: "Confirm",
        type: "default",
        height: 40,
        width: 200,
        useSubmitBehavior: true
    };
}
