
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AppIcon from "../AppIcon";
import { MenuItem } from "../SideMenu/SideMenu.vue";

@Component({
    components: {
        AppIcon
    }
})
export default class SideMenuItem extends Vue {
    @Prop({ type: String, required: false })
    public readonly to!: string;

    @Prop({ type: String, required: true })
    public readonly title!: string;

    @Prop({ type: Object, required: false })
    public readonly icon!: MenuItem["icon"];

    @Prop({ type: Function, required: false })
    public readonly action!: () => void | null;

    get classes(): Array<string | null> {
        const path = this.$route.fullPath;
        return ["side-menu-link", path.startsWith(this.to) ? "active" : null];
    }

    click(): void {
        if (this.action) {
            this.action();
        }
    }
}
