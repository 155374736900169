
import { MaintenanceBanner as Banner } from "@/models/MaintenanceBanner";
import { maintenanceBannerModule } from "@/store/modules/maintenanceBanner";
import { MaintenanceBannerActions } from "@/store/modules/maintenanceBanner/actions";
import { MaintenanceBannerGetters } from "@/store/modules/maintenanceBanner/getters";
import { MaintenanceBannerMutations } from "@/store/modules/maintenanceBanner/mutations";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({
    methods: {
        ...maintenanceBannerModule.mapActions({
            loadMaintenanceBanner:
                MaintenanceBannerActions.LoadMaintenanceBanner
        }),
        ...maintenanceBannerModule.mapMutations({
            showMaintenanceBanner:
                MaintenanceBannerMutations.ShowMaintenanceBanner
        })
    },
    computed: {
        ...maintenanceBannerModule.mapGetters({
            isMaintenanceBannerShown:
                MaintenanceBannerGetters.IsMaintenanceBannerShown,
            maintenanceBanner: MaintenanceBannerGetters.MaintenanceBanner
        })
    }
})
export default class MaintenanceBanner extends Vue {
    private readonly loadMaintenanceBanner!: () => Promise<void>;
    private readonly showMaintenanceBanner!: (visible: boolean) => void;

    protected readonly isMaintenanceBannerShown!: boolean;
    private readonly maintenanceBanner!: Banner;

    get maintenanceBannerContent(): string {
        let content = "Maintenance Banner Content";
        if (this.maintenanceBanner?.isVisible) {
            content = this.maintenanceBanner?.message ?? "";
        }
        return content;
    }

    async created(): Promise<void> {
        await this.loadMaintenanceBanner();
    }

    @Watch("isMaintenanceBannerShown")
    onVisibilityChanged(): void {
        if (this.isMaintenanceBannerShown) {
            const endDate = new Date(this.maintenanceBanner.toDateTime);
            const now = new Date();
            const duration = endDate.getTime() - now.getTime();
            setTimeout(() => {
                this.showMaintenanceBanner(false);
            }, duration);
        }
    }
}
