import { Customer } from "@/models/Customer";
import api from ".";

export const customerDataSourceEndpoint = `${api.defaults.baseURL}customers/datasource`;

function getFormDataFromCustomer(customer: Customer) {
    const formData = new FormData();
    formData.append("name", customer.name);
    formData.append("isCustomer", String(customer.isCustomer));
    formData.append("ikamId", String(customer.ikamId));
    if (customer.l1Number) {
        formData.append("l1Number", customer.l1Number);
    }
    if (customer.logo) {
        formData.append("logo", customer.logo);
    }
    if (customer.customerId) {
        formData.append("customerId", String(customer.customerId));
    }
    return formData;
}

function getCustomers(): Promise<Customer[]> {
    return api.get<Customer[]>("customers").then((r) => r.data);
}

function deleteCustomer(customerId: number): Promise<void> {
    return api.delete(`customers/${customerId}`);
}

function getCustomerById(customerId: number): Promise<Customer> {
    return api.get<Customer>(`customers/${customerId}`).then((r) => r.data);
}

function createCustomer(customer: Customer): Promise<void> {
    const formData = getFormDataFromCustomer(customer);

    return api.post("customers", formData, {
        headers: { "Content-Type": "multipart/form-data" }
    });
}

function saveCustomer(customer: Customer): Promise<void> {
    return api.put(`customers/${customer.customerId}`, customer).then(() => {
        if (customer.logo) {
            const formData = new FormData();
            formData.append("logo", customer.logo);
            return api.post(`customers/${customer.customerId}/logo`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
        }
    });
}

export default {
    getCustomers,
    deleteCustomer,
    getCustomerById,
    createCustomer,
    saveCustomer
};
