import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { Routes } from "./routes";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import PasswordReset from "@/pages/PasswordReset";
import RequestPasswordReset from "@/pages/RequestPasswordReset";
import AccountActivation from "@/pages/AccountActivation";
import TwoFactorActivation from "../pages/TwoFactorActivation";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: Routes.Home,
        name: "home",
        component: Home,
        meta: {
            title: "Home",
            allowedRoles: ["Admin", "SU", "MSU", "MU", "HV", "MV"]
        }
    },
    {
        path: Routes.Login,
        name: "login",
        component: Login,
        meta: {
            layout: "authLayout",
            title: "Login",
            allowAnonymous: true
        }
    },
    {
        path: Routes.RequestPasswordReset,
        name: "requestPasswordReset",
        component: RequestPasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.PasswordReset,
        name: "passwordReset",
        component: PasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.AccountActivation,
        name: "accountActivation",
        component: AccountActivation,
        meta: {
            layout: "authLayout",
            title: "Activate account",
            allowAnonymous: true
        }
    },
    {
        path: Routes.TwoFactorActivation,
        name: "twoFactorActivation",
        component: TwoFactorActivation,
        meta: {
            layout: "authLayout",
            title: "Activate two factor authentication",
            allowAnonymous: true
        }
    },
    {
        path: Routes.CreateRequest,
        name: "createRequest",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/CreateRequest"),
        meta: {
            title: "Create Request",
            allowedRoles: ["Admin", "SU"]
        }
    },
    {
        path: Routes.Markets,
        name: "markets",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/Markets"),
        meta: {
            title: "Markets",
            allowedRoles: ["SU", "MSU", "MU", "Admin"]
        }
    },
    {
        path: Routes.EditMarket + ":id",
        name: "editMarket",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/EditMarket"),
        meta: {
            title: "Edit market",
            allowedRoles: ["SU", "MSU", "MU", "Admin"]
        }
    },
    {
        path: Routes.CreateMarket,
        name: "createMarket",
        component: () =>
            import(/* webpackChunkName: "markets" */ "@/pages/CreateMarket"),
        meta: {
            title: "Create market",
            allowedRoles: ["SU", "MSU", "MU", "Admin"]
        }
    },
    {
        path: Routes.Customers,
        name: "customers",
        component: () =>
            import(/* webpackChunkName: "customers" */ "@/pages/Customers"),
        meta: {
            title: "Customers",
            allowedRoles: ["Admin", "SU"]
        }
    },
    {
        path: Routes.EditCustomer + ":id",
        name: "editCustomer",
        component: () =>
            import(/* webpackChunkName: "customers" */ "@/pages/EditCustomer"),
        meta: {
            title: "Edit Customer",
            allowedRoles: ["Admin", "SU"]
        }
    },
    {
        path: Routes.CreateCustomer,
        name: "createCustomer",
        component: () =>
            import(
                /* webpackChunkName: "customers" */ "@/pages/CreateCustomer"
            ),
        meta: {
            title: "Create Customer",
            allowedRoles: ["Admin", "SU"]
        }
    },
    {
        path: Routes.RequestsInProgress,
        name: "requestInProgress",
        component: () =>
            import(
                /* webpackChunkName: "request" */ "@/pages/RequestsInProgress"
            ),
        meta: {
            title: "Request in Progress",
            allowedRoles: ["Admin", "SU", "MSU", "MU", "HV", "MV"]
        }
    },
    {
        path: Routes.Archive,
        name: "archive",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/Archive"),
        meta: {
            title: "Archive",
            allowedRoles: ["Admin", "SU", "MSU", "MU", "HV", "MV"]
        }
    },
    {
        path: Routes.Drafts,
        name: "drafts",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/Drafts"),
        meta: {
            title: "Drafts",
            allowedRoles: ["Admin", "SU", "HV"]
        }
    },
    {
        path: Routes.Draft + ":id",
        name: "draft",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/EditDraft"),
        meta: {
            title: "Draft",
            allowedRoles: ["Admin", "SU"]
        }
    },
    {
        path: Routes.Request + ":id",
        name: "requestView",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/RequestView"),
        meta: {
            title: "Request",
            allowedRoles: ["Admin", "SU", "MSU", "MU", "HV", "MV"]
        }
    },
    {
        path: Routes.ArchivedRequest + ":id",
        name: "archivedRequestView",
        component: () =>
            import(/* webpackChunkName: "request" */ "@/pages/ArchivedRequest"),
        meta: {
            title: "Archived Request",
            allowedRoles: ["Admin", "SU", "MSU", "MU", "HV", "MV"]
        }
    },
    {
        path: Routes.Users,
        name: "users",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/Users"),
        meta: {
            title: "Users",
            allowedRoles: ["Admin", "SU", "MSU"]
        }
    },
    {
        path: Routes.EditUser + ":id",
        name: "editUser",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/EditUser"),
        meta: {
            title: "Edit User",
            allowedRoles: ["Admin", "SU", "MSU"]
        }
    },
    {
        path: Routes.CreateUser,
        name: "createUser",
        component: () =>
            import(/* webpackChunkName: "users" */ "@/pages/CreateUser"),
        meta: {
            title: "Create User",
            allowedRoles: ["Admin", "SU", "MSU"]
        }
    },
    {
        path: Routes.Error + ":code",
        name: "error",
        component: () => import("@/pages/Error"),
        meta: {
            title: "Error"
        }
    },
    {
        path: "*",
        name: "404",
        redirect: () => Routes.Error + "404"
    }
];

const router = new VueRouter({
    routes: routes,
    mode: "history"
});

export default router;
