import { Customer } from "@/models/Customer";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface CustomerState {
    customers: Customer[];
    selectedCustomer: Customer | null;
    error: string | null;
    isLoading: boolean;
    isEdited: boolean;
}

const customerState: CustomerState = {
    customers: [],
    selectedCustomer: null,
    error: null,
    isLoading: false,
    isEdited: false
};

const customer: Module<CustomerState, RootState> = {
    namespaced: true,
    state: customerState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const customerModule = createNamespacedHelpers("customer");

export default customer;
