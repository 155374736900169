
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import DxAccordion from "devextreme-vue/accordion";

@Component({
    components: { DxAccordion }
})
export default class SingleAccordion extends Vue {
    @Prop({ type: String, required: true })
    public readonly title!: string;

    @Prop({ type: String, required: false })
    public readonly subtitle!: string | undefined;

    @Prop({ type: String, required: false })
    public readonly subtitleClass!: string | undefined;

    @PropSync("open", { type: Boolean, default: false })
    public readonly isOpen!: boolean;
}
