
import Vue from "vue";
import Component from "vue-class-component";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import Errors from "./BackendResponseErrors.json";
import { DxPopup, DxToolbarItem, DxPosition } from "devextreme-vue/popup";
import GenericPasswordChangeForm from "@/components/GenericPasswordChangeForm";
import notify from "devextreme/ui/notify";
import { Routes } from "@/router/routes";
import {
    formatValidationErrors,
    isValidationError
} from "@/services/responseErrorUtils";

@Component({
    components: {
        DxPopup,
        DxToolbarItem,
        DxPosition,
        GenericPasswordChangeForm
    },
    methods: {
        ...accountModule.mapActions({
            activateAccount: AccountActions.ActivateAccount
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors
        })
    }
})
export default class AccountActivationForm extends Vue {
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly activateAccount!: (payload: {
        token: string;
        password: string;
    }) => Promise<string>;
    protected token = "";
    popupVisible = false;

    closeButtonOptions = {
        type: "default",
        text: "Back to login",
        onClick: (): void => {
            this.$router.push({ name: "login" });
        }
    };

    mounted(): void {
        const queryToken = this.$route.query.token as string;
        if (queryToken == null || queryToken.length == 0) {
            notify("Missing token.", "error", 20000);
        } else {
            this.token = queryToken;
        }
    }

    async submit(password: string): Promise<void> {
        const twoFactorToken = await this.activateAccount({
            token: this.$route.query.token as string,
            password: password
        });

        const sessionErrors = this.getErrors();
        this.setErrors(null);

        // structure of the default error: ['code', 'message']
        // in all these cases code is 400, so we need to define an error by message
        if (sessionErrors !== undefined && sessionErrors !== null) {
            if (isValidationError(sessionErrors[0])) {
                notify(formatValidationErrors(sessionErrors), "error", 10000);
            } else if (
                sessionErrors[0].includes(Errors.BadRequest.Error) &&
                sessionErrors.length === 2
            ) {
                if (sessionErrors[1].includes(Errors.InvalidToken.Error)) {
                    notify(Errors.InvalidToken.Message, "error", 10000);
                } else if (
                    sessionErrors[1].includes(Errors.UserNotFound.Error)
                ) {
                    notify(Errors.UserNotFound.Message, "error", 10000);
                } else if (
                    sessionErrors[1].includes(Errors.NetworkError.Error)
                ) {
                    notify(Errors.NetworkError.Message, "error", 10000);
                } else {
                    notify(Errors.BadRequest.Message, "error", 10000);
                }
            } else {
                notify(Errors.Default.Message, "error", 10000);
            }
        } else {
            if (twoFactorToken) {
                this.$router.push(
                    Routes.TwoFactorActivation + "?token=" + twoFactorToken
                );
            } else {
                this.popupVisible = true;
            }
        }
    }
}
