import { RequestListFilter } from "@/models/request/list/RequestListFilter";
import {
    RequestListPagination,
    getDefaultRequestListPagination
} from "@/models/request/list/RequestListPagination";
import { RequestListSorting } from "@/models/request/list/RequestListSorting";

export const standardRequestListFilterValues: Readonly<
    Required<RequestListFilter>
> = {
    requestId: [""],
    typeOfRequest: [""],
    customer: [""],
    customerType: [""],
    expectedVolume: [""],
    customerStatus: [""],
    marketDeadline: [""],
    customerDeadline: [""],
    stateTool: [""],
    IKAM: [""],
    comment: [""],
    supplyScenarios: [""],
    totalFleet: [""],
    countMarkets: [""],
    modifiedBy: [""],
    finishedDate: [""],
    jobDriver: [""]
};

export const standardRequestListPaginationValues: Readonly<
    Required<RequestListPagination>
> = getDefaultRequestListPagination();

export const standardRequestListSortingValues: Required<
    Readonly<RequestListSorting>
> = {
    sortRequestId: "asc",
    sortRequestType: "asc",
    sortCustomer: "asc",
    sortCustomerType: "asc",
    sortExpectedVolume: "asc",
    sortCustomerStatus: "asc",
    sortMarketDeadline: "asc",
    sortCustomerDeadline: "asc",
    sortState: "asc",
    sortIKAM: "asc",
    sortCreatedBy: "asc",
    sortModifiedBy: "asc",
    sortFinishedDate: "asc",
    sortFinishedBy: "asc",
    sortAdminComment: "asc",
    sortSupplyScenarios: "asc",
    sortTotalFleet: "asc",
    sortTotalMarkets: "asc",
    sortJobDriver: "asc"
};
