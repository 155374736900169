import maintenanceBannerApi from "@/api/maintenanceBannerApi";
import { RootState } from "@/store";
import { ActionTree } from "vuex";
import { MaintenanceBannerState } from ".";
import { MaintenanceBannerMutations } from "./mutations";
import { MaintenanceBanner } from "@/models/MaintenanceBanner";
import { Commit } from "vuex";

export enum MaintenanceBannerActions {
    LoadMaintenanceBanner = "loadMaintenanceBanner",
    CreateMaintenanceBanner = "createMaintenanceBanner"
}

function showMaintenanceBanner(
    banner: MaintenanceBanner,
    commit: Commit
): void {
    const now = new Date();
    let duration = 0;
    const startDate = new Date(banner.fromDateTime);
    const endDate = new Date(banner.toDateTime);
    if (banner.isVisible) {
        if (startDate < now && now < endDate) {
            commit(MaintenanceBannerMutations.ShowMaintenanceBanner, true);
        } else if (startDate > now) {
            commit(MaintenanceBannerMutations.ShowMaintenanceBanner, false);
            duration = startDate.getTime() - now.getTime();
            setTimeout(() => {
                commit(MaintenanceBannerMutations.ShowMaintenanceBanner, true);
            }, duration);
        }
    } else {
        commit(MaintenanceBannerMutations.ShowMaintenanceBanner, false);
    }
}

const actions: ActionTree<MaintenanceBannerState, RootState> = {
    async [MaintenanceBannerActions.LoadMaintenanceBanner]({
        commit
    }): Promise<void> {
        const result = await maintenanceBannerApi.getMaintenanceBanner();
        commit(MaintenanceBannerMutations.SetMaintenanceBanner, result);
        showMaintenanceBanner(result, commit);
    },
    async [MaintenanceBannerActions.CreateMaintenanceBanner](
        { dispatch },
        banner: MaintenanceBanner
    ): Promise<void> {
        await maintenanceBannerApi.createMaintenanceBanner(banner);
        await dispatch(MaintenanceBannerActions.LoadMaintenanceBanner);
    }
};

export default actions;
