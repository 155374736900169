import accountApi from "@/api/accountApi";
import { RootState } from "@/store";
import { ActionContext, ActionTree } from "vuex";
import { AccountState, SESSION_KEY } from ".";
import { AccountMutations } from "./mutations";
import { Session } from "@/models/Session";
import { getValidationErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import localStorageService from "@/services/localStorageService";
import { LoginResult } from "../../../models/LoginResult";

export enum AccountActions {
    LogUserIn = "logUserIn",
    LogUserInTwoFactor = "logUserInTwoFactor",
    LogUserOut = "logUserOut",
    VerifyLogin = "verifyLogin",
    RequestPasswordReset = "requestPasswordReset",
    ResetPassword = "resetPassword",
    ActivateAccount = "activateAccount",
    GetTwoFactorResetToken = "getTwoFactorResetToken",
    GetTwoFactorSetupCode = "getTwoFactorSetupCode",
    ActivateTwoFactorAuthentication = "activateTwoFactorAuthentication"
}

const actions: ActionTree<AccountState, RootState> = {
    async [AccountActions.LogUserIn](
        context,
        payload: { username: string; password: string }
    ) {
        try {
            const loginResult: LoginResult | null = await accountApi.logUserIn(
                payload.username,
                payload.password
            );

            if (loginResult != null && loginResult.finishedSuccessfully) {
                await context.commit(
                    AccountMutations.SetSession,
                    loginResult.session
                );
                localStorageService.save(SESSION_KEY, loginResult.session);
            }

            await context.commit(AccountMutations.SetLoginResult, loginResult);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.LogUserInTwoFactor](
        context,
        payload: {
            twoFactorPassword: string;
        }
    ) {
        try {
            const session: Session | null = await accountApi.logUserInTwoFactor(
                payload.twoFactorPassword
            );

            if (session != null) {
                await context.commit(AccountMutations.SetSession, session);
                localStorageService.save(SESSION_KEY, session);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.VerifyLogin](context) {
        try {
            const session: Session | null = await accountApi.verifyLogin();
            await context.commit(AccountMutations.SetSession, session);
            localStorageService.save(SESSION_KEY, session);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.LogUserOut](context) {
        try {
            await accountApi.logUserOut();
            await context.commit(AccountMutations.SetSession, null);
            localStorageService.remove(SESSION_KEY);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.RequestPasswordReset](
        context,
        payload: { email: string }
    ) {
        try {
            await accountApi.requestPasswordReset(payload.email);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.ResetPassword](
        context,
        payload: { token: string; newPassword: string }
    ) {
        try {
            await accountApi.resetPassword(payload.token, payload.newPassword);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.ActivateAccount](
        context,
        payload: { token: string; password: string }
    ) {
        try {
            return await accountApi.activateAccount(
                payload.token,
                payload.password
            );
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.GetTwoFactorResetToken](
        context,
        payload: { userName: string; recoveryCode: string }
    ) {
        try {
            return await accountApi.getTwoFactorResetToken(
                payload.userName,
                payload.recoveryCode
            );
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.GetTwoFactorSetupCode](context, payload: string) {
        try {
            return await accountApi.getTwoFactorSetupCode(payload);
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [AccountActions.ActivateTwoFactorAuthentication](
        context,
        payload: {
            token: string;
            code: string;
        }
    ) {
        try {
            await accountApi.activateTwoFactorAuthentication(
                payload.token,
                payload.code
            );
        } catch (e) {
            handleReponseError(e, context);
        }
    }
};

export function handleReponseError(
    error: Error,
    context: ActionContext<AccountState, RootState>
): void {
    context.commit(
        AccountMutations.SetErrors,
        getValidationErrorTextsFromErrorResponse(error)
    );
}

export default actions;
