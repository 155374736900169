
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class RequestProgressBar extends Vue {
    @Prop({ type: Number, required: true })
    public readonly pendingMarkets!: number;

    @Prop({ type: Number, required: true })
    public readonly pendingHQ!: number;

    @Prop({ type: Number, required: true })
    public readonly approved!: number;

    @Prop({ type: Number, required: true })
    public readonly total!: number;

    @Prop({ type: Array, required: true })
    public readonly pendingCountryCodes!: string[];

    calculateWidth(number: number): string {
        return `width: ${(number / this.total) * 100}%;`;
    }

    get countryCodesString(): string {
        if (this.pendingCountryCodes.length === 0) {
            return "-";
        }
        return this.pendingCountryCodes.join(", ");
    }
}
