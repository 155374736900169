
import Vue from "vue";
import Component from "vue-class-component";
import AppIcon from "../AppIcon";
import { Routes } from "@/router/routes";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { Session } from "@/models/Session";
import TermsOfUsePopup from "@/components/TermsOfUsePopup";
import ReleaseNotesPopup from "@/components/ReleaseNotesPopup";

@Component({
    components: {
        AppIcon,
        TermsOfUsePopup,
        ReleaseNotesPopup
    },
    computed: {
        ...accountModule.mapGetters({
            session: AccountGetters.Session
        })
    },
    methods: {
        ...accountModule.mapActions({
            logUserOut: AccountActions.LogUserOut
        })
    }
})
export default class UserBadge extends Vue {
    private readonly session!: Session | null;
    protected readonly logUserOut!: () => Promise<void>;

    protected accountMenuVisible = false;
    protected infoMenuVisible = false;
    protected termsVisible = false;
    protected releaseNotesVisible = false;

    get fullname(): string {
        return this.session?.User.firstName + " " + this.session?.User.lastName;
    }

    async logout(): Promise<void> {
        await this.logUserOut();
        this.$router.push(Routes.Login);
    }

    showMenu(isAccountMenu: boolean): void {
        if (isAccountMenu) {
            this.accountMenuVisible = !this.accountMenuVisible;
            this.infoMenuVisible = false;
        } else {
            this.infoMenuVisible = !this.infoMenuVisible;
            this.accountMenuVisible = false;
        }

        this.$el.addEventListener("click", (e) => e.stopPropagation());
        document.onclick = () => {
            this.accountMenuVisible = false;
            this.infoMenuVisible = false;
            document.onclick = null;
        };
    }
}
