import {
    RequestListFilter,
    defaultRequestListFilter
} from "./RequestListFilter";
import {
    RequestListPagination,
    getDefaultRequestListPagination
} from "./RequestListPagination";
import { RequestListSorting } from "./RequestListSorting";

export interface RequestListOptions {
    pagination: RequestListPagination;
    sorting: RequestListSorting;
    filter: RequestListFilter;
}

export function getDefaultRequestListOptions(): RequestListOptions {
    return {
        pagination: getDefaultRequestListPagination(),
        filter: defaultRequestListFilter(),
        sorting: {}
    };
}
