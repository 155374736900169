
import Vue from "vue";
import Component from "vue-class-component";
import packageJson from "@/../package.json";

@Component
export default class AppFooter extends Vue {
    version = packageJson.version;

    get currentYear(): number {
        return new Date().getFullYear();
    }
}
