import { Customer } from "@/models/Customer";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CustomerState } from ".";

export enum CustomerGetters {
    Customers = "customers",
    SelectedCustomer = "selectedCustomer",
    Error = "errors",
    IsEdited = "isEdited"
}

const getters: GetterTree<CustomerState, RootState> = {
    [CustomerGetters.Customers](state): Customer[] {
        return state.customers;
    },
    [CustomerGetters.SelectedCustomer](state): Customer | null {
        return state.selectedCustomer;
    },
    [CustomerGetters.Error](state): string | null {
        return state.error;
    },
    [CustomerGetters.IsEdited](state): boolean {
        return state.isEdited;
    }
};

export default getters;
