import {
    defaultModelGridConfig,
    ModelGridConfig
} from "@/models/ModelGridConfig";
import { MarketRequest } from "@/models/request/MarketRequest";
import { RequestDetails } from "@/models/request/RequestDetails";
import { ValuesData } from "@/models/ValuesData";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import compare from "./compare";
import { ImportResult } from "@/models/request/ImportResult";

export interface MarketRequestState {
    request: RequestDetails | null;
    valuesData: ValuesData;
    modelGridConfig: ModelGridConfig;
    selectedMarketRequest: MarketRequest | null;
    errors: string[] | null;
    importResult: ImportResult | null;
    isLoading: boolean;
    newAttachments: File[];
    removedAttachmentIds: number[];
    modelsInfo: {
        addedModelIds: number[];
        removedModelIds: number[];
    };
}

const state: MarketRequestState = {
    request: null,
    valuesData: {
        models: [],
        selectedModels: [],
        values: [],
        errors: []
    },
    modelGridConfig: defaultModelGridConfig(),
    selectedMarketRequest: null,
    errors: null,
    importResult: null,
    isLoading: false,
    newAttachments: [],
    removedAttachmentIds: [],
    modelsInfo: {
        addedModelIds: [],
        removedModelIds: []
    }
};

const marketRequest: Module<MarketRequestState, RootState> = {
    namespaced: true,
    state: state,
    actions: actions,
    getters: getters,
    mutations: mutations,
    modules: {
        compare
    }
};

export const marketRequestModule = createNamespacedHelpers("marketRequest");

export default marketRequest;
