import { Request } from "@/models/request/Request";
import { MarketRequest } from "@/models/request/MarketRequest";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { RequestState } from ".";
import { RequestListItem } from "@/models/request/list/RequestListItem";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import { RequestInfo } from "@/models/request/RequestInfo";
import { Question } from "@/models/Question";
import { RequestModelFilter } from "@/models/request/RequestVehicleModelFilter";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";

export enum RequestGetters {
    MarketRequest = "marketRequest",
    Markets = "markets",
    RequestFormConfig = "requestFormConfig",
    RequestModelFilter = "requestModelFilter",
    RequestQuestions = "requestQuestions",
    Requests = "requests",
    IsEdited = "isEdited",
    Info = "info",
    FormConfigTemplate = "formConfigTemplate",
    IsNew = "isNew",
    IsLoading = "isLoading",
    Errors = "errors",
    RequestGridConfig = "requestGridConfig"
}

const getters: GetterTree<RequestState, RootState> = {
    [RequestGetters.MarketRequest](state): Request {
        return state.request;
    },
    [RequestGetters.Markets](state): MarketRequest[] {
        return state.request.marketRequests;
    },
    [RequestGetters.RequestFormConfig](state): RequestFormConfig | null {
        return state.request.requestFormConfig;
    },
    [RequestGetters.RequestQuestions](state): Question[] {
        return state.request.questions;
    },
    [RequestGetters.Requests](state): RequestListItem[] {
        return state.requests;
    },
    [RequestGetters.IsEdited](state): boolean {
        return state.isEdited;
    },
    [RequestGetters.Info](state): RequestInfo {
        return state.info;
    },
    [RequestGetters.FormConfigTemplate](state): RequestFormConfig | null {
        return state.formConfigTemplate;
    },
    [RequestGetters.RequestModelFilter](state): RequestModelFilter | null {
        return state.request.requestVehicleModelFilter;
    },
    [RequestGetters.IsNew](state): boolean {
        return state.isNew;
    },
    [RequestGetters.IsLoading](state): boolean {
        return state.isLoading;
    },
    [RequestGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [RequestGetters.RequestGridConfig](state): RequestGridConfig {
        return state.requestGridConfig;
    }
};

export default getters;
