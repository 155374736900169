import { VehicleModel } from "@/models/VehicleModel";
import { ModelsInfo } from "@/models/ModelsInfo";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { ModelState } from ".";

export enum ModelGetters {
    Models = "models",
    Info = "info"
}

const getters: GetterTree<ModelState, RootState> = {
    [ModelGetters.Models](state): VehicleModel[] {
        return state.models;
    },
    [ModelGetters.Info](state): ModelsInfo | null {
        return state.info;
    }
};

export default getters;
