import { UpdateEntity } from "@/models/UpdateEntity";
import { User } from "@/models/User";
import { UserMarket } from "@/models/UserMarket";
import Vue from "vue";
import { MutationTree } from "vuex";
import { UserState } from ".";

export enum UserMutations {
    SetUsers = "addUsers",
    SetIkams = "setIkams",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    RemoveUserById = "removeUserById",
    SetUser = "setUser",
    UpdateUser = "updateUser",
    AddUserMarkets = "addUserMarkets",
    RemoveUserMarkets = "removeUserMarkets",
    UpdateUserMarket = "updateUserMarket"
}

const mutations: MutationTree<UserState> = {
    [UserMutations.SetUsers](state, payload: User[]): void {
        state.users = payload;
    },
    [UserMutations.SetIkams](state, payload: User[]): void {
        state.ikams = payload;
    },
    [UserMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [UserMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [UserMutations.RemoveUserById](state, payload: number) {
        state.users = state.users.filter((u) => u.id !== payload);
    },
    [UserMutations.SetUser](state, payload: Partial<User> | null) {
        state.user = payload;
        state.isEdited = false;
    },
    [UserMutations.UpdateUser](state, payload: Partial<User>) {
        if (state.user) {
            state.user = { ...state.user, ...payload };
            state.isEdited = true;
        }
    },
    [UserMutations.AddUserMarkets](state, payload: number[]) {
        if (!state.user) {
            return;
        }
        const userMarkets = state.user.marketUsers;
        if (userMarkets) {
            payload.forEach((id) => {
                userMarkets.push({
                    marketId: id,
                    position: null,
                    department: null
                });
            });
        } else {
            state.user.marketUsers = payload.map((id) => ({
                marketId: id,
                position: null,
                department: null
            }));
        }
    },
    [UserMutations.RemoveUserMarkets](state, payload: number[]) {
        if (state.user?.marketUsers) {
            state.user.marketUsers = state.user.marketUsers.filter(
                (um) => !payload.includes(um.marketId)
            );
        }
    },
    [UserMutations.UpdateUserMarket](
        state,
        payload: UpdateEntity<UserMarket, number>
    ) {
        if (state.user?.marketUsers) {
            const userMarkets = state.user.marketUsers;
            const userMarket = userMarkets.find(
                (um) => um.marketId === payload.key
            );
            if (userMarket) {
                const index = userMarkets.indexOf(userMarket);
                Vue.set(userMarkets, index, {
                    ...userMarket,
                    ...payload.fields
                });
            }
        }
    }
};

export default mutations;
