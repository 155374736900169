
import logo from "@/assets/img/BMW_logo_(white).svg";
import PasswordResetForm from "@/components/PasswordResetForm";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
    components: { PasswordResetForm }
})
export default class PasswordReset extends Vue {
    private logo: string = logo;
}
