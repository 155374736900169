import { ReleaseEmail } from "@/models/ReleaseEmail";
import api from ".";

function create(releaseEmail: ReleaseEmail): Promise<void> {
    const data = new FormData();

    data.append("releaseDateTime", releaseEmail.releaseDateTime.toUTCString());
    data.append("message", releaseEmail.message);

    if (releaseEmail.attachment) {
        data.append("attachment", releaseEmail.attachment);
    }

    return api.post("release-email", data, {
        headers: { "Content-Type": "multipart/form-data" }
    });
}

export default {
    create
};
