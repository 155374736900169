import { MaintenanceBanner } from "@/models/MaintenanceBanner";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MaintenanceBannerState } from ".";

export enum MaintenanceBannerGetters {
    MaintenanceBanner = "maintenanceBanner",
    IsMaintenanceBannerShown = "isMaintenanceBannerShown"
}

const getters: GetterTree<MaintenanceBannerState, RootState> = {
    [MaintenanceBannerGetters.MaintenanceBanner](
        state
    ): MaintenanceBanner | null {
        return state.maintenanceBanner;
    },
    [MaintenanceBannerGetters.IsMaintenanceBannerShown](state): boolean {
        return state.isMaintenanceBannerShown;
    }
};

export default getters;
