import { Session } from "@/models/Session";
import { LoginResult } from "@/models/LoginResult";
import api from ".";

async function logUserIn(
    username: string,
    password: string
): Promise<LoginResult> {
    if (username.length == 0 || password.length == 0) {
        throw "Username or password null or empty";
    }

    const formData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    let response = await api.post("/user/login", formData);

    if (response.status == 200) {
        if (response.data.finishedSuccessfully == true) {
            response = await api.get("/user");

            return {
                finishedSuccessfully: true,
                isMultiFactorRequired: false,
                session: {
                    User: response.data,
                    ValidUntil: Date.now() + 60000 // 60 seconds
                }
            };
        } else {
            return response.data;
        }
    }

    throw response.status;
}

async function logUserInTwoFactor(
    twoFactorPassword: string
): Promise<Session | null> {
    if (twoFactorPassword.length == 0) {
        throw "Username or password null or empty";
    }
    const formData = new FormData();

    formData.append("totp", twoFactorPassword);

    let response = await api.post("/user/login/2fa", formData);

    if (response.status == 200) {
        if (response.data.finishedSuccessfully == true) {
            response = await api.get("/user");

            return {
                User: response.data,
                ValidUntil: Date.now() + 60000 // 60 seconds
            };
        } else {
            return response.data;
        }
    }

    throw response.status;
}

async function verifyLogin(): Promise<Session | null> {
    try {
        const response = await api.get("/user");

        if (response.status == 200) {
            return {
                User: response.data,
                ValidUntil: Date.now() + 60000 // 60 seconds
            };
        }

        throw response.status;
    } catch {
        return null;
    }
}

async function logUserOut(): Promise<void> {
    const response = await api.post("/user/logout");

    if (response.status != 200) throw response.data;
}

async function requestPasswordReset(email: string): Promise<void> {
    const formData = new FormData();
    formData.append("emailaddress", email);
    const response = await api.post("/user/password/request", formData);

    if (response.status != 200) throw response.data;
}

async function resetPassword(
    token: string,
    newPassword: string
): Promise<void> {
    const formData = new FormData();
    formData.append("newPassword", newPassword);
    const response = await api.post(
        "/user/password/reset?token=" + token,
        formData
    );

    if (response.status != 200) throw response.data;
}

async function activateAccount(
    token: string,
    password: string
): Promise<string> {
    const formData = new FormData();
    formData.append("password", password);
    const response = await api.post("/user/activate?token=" + token, formData);

    if (response.status != 200) throw response.data;
    return response.data;
}

async function getTwoFactorResetToken(
    userName: string,
    recoveryCode: string
): Promise<string> {
    const formData = new FormData();
    formData.append("recoveryCode", recoveryCode);
    const response = await api.post(`/user/${userName}/2fa/recovery`, formData);
    if (response.status != 200) throw response.data;
    return response.data;
}

async function getTwoFactorSetupCode(token: string): Promise<string> {
    const response = await api.get("2fa/recovery?token=" + token, {
        responseType: "arraybuffer"
    });
    if (response.status != 200) throw response.data;
    const image = btoa(
        new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
        )
    );
    return `data:${response.headers[
        "content-type"
    ].toLowerCase()};base64,${image}`;
}

async function activateTwoFactorAuthentication(
    token: string,
    code: string
): Promise<void> {
    const formData = new FormData();
    formData.append("code", code);
    const response = await api.post("/2fa/code?token=" + token, formData);
    if (response.status != 200) throw response.data;
}

export default {
    logUserIn,
    logUserInTwoFactor,
    verifyLogin,
    requestPasswordReset,
    resetPassword,
    activateAccount,
    logUserOut,
    getTwoFactorResetToken,
    getTwoFactorSetupCode,
    activateTwoFactorAuthentication
};
