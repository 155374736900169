import { MutationTree } from "vuex";
import { TipState } from ".";
import { Tip } from "@/models/Tip";

export enum TipMutations {
    SetTips = "setTips",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    UpdateTip = "updateTip"
}

const mutations: MutationTree<TipState> = {
    [TipMutations.SetTips](state, payload: Tip[]): void {
        state.tips = payload;
    },
    [TipMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [TipMutations.SetLoading](state, payload: boolean): void {
        if (payload) {
            state.tips = [];
            state.errors = null;
        }
        state.loading = payload;
    },
    [TipMutations.UpdateTip](state) {
        if (state.tips) {
            state.tips = { ...state.tips };
        }
    }
};

export default mutations;
