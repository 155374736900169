
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule,
    DxEmptyItem,
    DxButtonItem
} from "devextreme-vue/form";
import Vue from "vue";
import Component from "vue-class-component";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { DxPopup, DxToolbarItem, DxPosition } from "devextreme-vue/popup";

@Component({
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxEmailRule,
        DxStringLengthRule,
        DxEmptyItem,
        DxButtonItem,
        DxPopup,
        DxToolbarItem,
        DxPosition
    },
    methods: {
        ...accountModule.mapActions({
            requestPasswordReset: AccountActions.RequestPasswordReset
        })
    }
})
export default class PasswordResetForm extends Vue {
    private readonly requestPasswordReset!: (payload: {
        email: string;
    }) => Promise<void>;

    requestPasswordResetModel = {
        email: ""
    };

    emailOptions = {
        placeholder: "Email address"
    };

    buttonOptions = {
        text: "Request password reset",
        type: "default",
        height: 40,
        width: 220,
        useSubmitBehavior: true
    };

    closeButtonOptions = {
        type: "default",
        text: "Back to login",
        onClick: (): void => {
            this.$router.push({ name: "login" });
        }
    };

    popupVisible = false;

    async submit(): Promise<void> {
        await this.requestPasswordReset({
            email: this.requestPasswordResetModel.email
        });

        this.popupVisible = true;
    }
}
