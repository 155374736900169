import { ComparisonDetailsDTO } from "@/models/request/compare/ComparisonDetailsDTO";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { SupplyScenario } from "@/models/request/SupplyScenario";

export interface MarketRequestCompareState {
    comparisonSettings: RequestComparisonSettings | null;
    comparisonDetails: ComparisonDetailsDTO | null;
    errors: string[] | null;
    isLoading: boolean;
    originalRequestSelectedSupplyScenario: SupplyScenario | null;
    comparedRequestSelectedSupplyScenario: SupplyScenario | null;
}

const state: MarketRequestCompareState = {
    comparisonSettings: null,
    comparisonDetails: null,
    errors: null,
    isLoading: false,
    originalRequestSelectedSupplyScenario: null,
    comparedRequestSelectedSupplyScenario: null
};

const marketRequestCompare: Module<MarketRequestCompareState, RootState> = {
    namespaced: true,
    state: state,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const marketRequestCompareModule = createNamespacedHelpers(
    "marketRequest/compare"
);

export default marketRequestCompare;
