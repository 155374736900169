import { Market } from "@/models/market/Market";
import { MarketDetails } from "@/models/market/MarketDetails";
import { MarketInfo } from "@/models/market/MarketInfo";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface MarketState {
    markets: Market[];
    info: MarketInfo;
    marketDetails: MarketDetails | null;
    newAttachments: File[];
    removedAttachmentIds: number[];
    errors: string[] | null;
    isLoading: boolean;
    isEdited: boolean;
}

const marketState: MarketState = {
    markets: [],
    info: {
        currencies: [],
        countryCodes: [],
        marketTypes: [],
        regions: []
    },
    newAttachments: [],
    removedAttachmentIds: [],
    marketDetails: null,
    errors: null,
    isLoading: false,
    isEdited: false
};

const market: Module<MarketState, RootState> = {
    namespaced: true,
    state: marketState,
    actions: actions,
    mutations: mutations,
    getters: getters
};

export const marketModule = createNamespacedHelpers("market");

export default market;
