import { ModelGridConfig } from "@/models/ModelGridConfig";
import { MarketRequest } from "@/models/request/MarketRequest";
import { RequestDetails } from "@/models/request/RequestDetails";
import { ValuesData } from "@/models/ValuesData";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { MarketRequestState } from ".";
import { ImportResult } from "@/models/request/ImportResult";

export enum MarketRequestGetters {
    Request = "request",
    SelectedMarketRequest = "selectedMarketRequest",
    ValuesData = "valuesData",
    ModelGridConfig = "modelGridConfig",
    IsLoading = "isLoading",
    Errors = "errors",
    ImportResult = "importResult"
}

const getters: GetterTree<MarketRequestState, RootState> = {
    [MarketRequestGetters.Request](state): RequestDetails | null {
        return state.request;
    },
    [MarketRequestGetters.SelectedMarketRequest](state): MarketRequest | null {
        return state.selectedMarketRequest;
    },
    [MarketRequestGetters.ValuesData](state): ValuesData {
        return state.valuesData;
    },
    [MarketRequestGetters.ModelGridConfig](state): ModelGridConfig {
        return state.modelGridConfig;
    },
    [MarketRequestGetters.IsLoading](state): boolean {
        return state.isLoading;
    },
    [MarketRequestGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [MarketRequestGetters.ImportResult](state): ImportResult | null {
        return state.importResult;
    }
};

export default getters;
