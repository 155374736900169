import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Tip } from "@/models/Tip";

export interface TipState {
    tips: Tip[];
    errors: string[] | null;
    loading: boolean;
}

const tipState: TipState = {
    tips: [],
    errors: null,
    loading: false
};

const tip: Module<TipState, RootState> = {
    namespaced: true,
    state: tipState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const tipModule = createNamespacedHelpers("tip");

export default tip;
