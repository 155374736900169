import { MaintenanceBanner } from "@/models/MaintenanceBanner";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface MaintenanceBannerState {
    maintenanceBanner: MaintenanceBanner | null;
    isMaintenanceBannerShown: boolean;
}

const maintenanceBannerState: MaintenanceBannerState = {
    maintenanceBanner: null,
    isMaintenanceBannerShown: false
};

const maintenanceBanner: Module<MaintenanceBannerState, RootState> = {
    namespaced: true,
    state: maintenanceBannerState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const maintenanceBannerModule =
    createNamespacedHelpers("maintenanceBanner");

export default maintenanceBanner;
