import { RequestListItem } from "@/models/request/list/RequestListItem";
import CustomStore from "devextreme/data/custom_store";
import { Commit } from "vuex";
import { parseLoadOptions } from "./loadOptionParse";
import { RequestMutations } from "@/store/modules/request/mutations";
import { getValidationErrorTextsFromErrorResponse } from "../responseErrorUtils";
import { AxiosResponse } from "axios";
import marketRequestApi from "@/api/marketRequestApi";
import { RequestState } from "@/store/modules/request";
import { LoadOptions } from "devextreme/data";

export const createRequestListItemDataSource = (
    commit: Commit,
    state: RequestState,
    requestState: string | null,
    marketId: number | null,
    marketRequestState: string | null
): CustomStore<RequestListItem, number> => {
    return new CustomStore<RequestListItem, number>({
        key: "requestId",
        async load(loadOptions) {
            const options: LoadOptions & { dataField?: string } = loadOptions;

            // dataField is not null when the custom store tries to load data for header filter
            // It can be if the necessary data is not provided directly to header filter
            // Since this request has no proper paging implementing it on the backend side can lead sending
            // a bunch of big requests that can return thousands of values
            // So if the data for header filter is not provided directly then it will calculated from locally stored requests
            if (options.dataField) {
                return {
                    data: state.requests ?? [],
                    totalCount: state.requests?.length ?? 0
                };
            }

            try {
                const request = parseLoadOptions(options);
                const response = await marketRequestApi.getRequestDataSource(
                    requestState,
                    marketId,
                    marketRequestState,
                    request
                );
                commit(
                    RequestMutations.SetRequests,
                    // Deep copy to avoid vuex state mutation
                    JSON.parse(JSON.stringify(response.data))
                );
                return response;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const response: AxiosResponse = error.response;

                if (response) {
                    commit(
                        RequestMutations.SetErrors,
                        getValidationErrorTextsFromErrorResponse(error)
                    );
                } else {
                    commit(RequestMutations.SetErrors, "Network error!");
                }
                return Promise.resolve({
                    data: [],
                    totalCount: 0
                });
            }
        },
        update() {
            return Promise.resolve();
        }
    });
};
