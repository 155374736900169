import { Customer } from "@/models/Customer";
import { MutationTree } from "vuex";
import { CustomerState } from ".";

export enum CustomerMutations {
    SetCustomers = "setCustomers",
    RemoveCustomer = "removeCustomer",
    SetError = "setErrors",
    SetIKAMs = "setIKAMs",
    SetSelectedCustomer = "setSelectedCustomer",
    UpdateSelectedCustomer = "updateSelectedCustomer",
    SetIKAMById = "setIKAMById",
    SetLoading = "setLoading"
}

const mutations: MutationTree<CustomerState> = {
    [CustomerMutations.SetCustomers](state, payload: Customer[]): void {
        state.customers = payload;
    },
    [CustomerMutations.RemoveCustomer](state, payload: number): void {
        const customerIndex = state.customers.findIndex(
            (c) => c.customerId === payload
        );
        state.customers.splice(customerIndex, 1);
    },
    [CustomerMutations.SetError](state, payload: string | null): void {
        state.error = payload;
    },
    [CustomerMutations.SetSelectedCustomer](
        state,
        payload: Customer | null
    ): void {
        state.selectedCustomer = payload;
        state.isEdited = false;
    },
    [CustomerMutations.UpdateSelectedCustomer](
        state,
        payload: Partial<Customer>
    ): void {
        if (state.selectedCustomer) {
            state.selectedCustomer = {
                ...state.selectedCustomer,
                ...payload
            };
            state.isEdited = true;
        }
    },
    [CustomerMutations.SetIKAMById](state, payload: number) {
        if (state.selectedCustomer) {
            state.selectedCustomer.ikamId = payload;
        }
    },
    [CustomerMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    }
};

export default mutations;
