import { DataSourceRequest } from "@/models/DataSourceRequest";
import { LoadOptions } from "devextreme/data";

type SortDescription = {
    selector: string;
    desc: boolean;
};

export function parseLoadOptions(options: LoadOptions): DataSourceRequest {
    const request: DataSourceRequest = {
        filter: null,
        sort: null,
        pagination: {
            take: options.take ?? 20,
            skip: options.skip ?? 0
        }
    };

    if (options.filter && options.filter.length) {
        const filter: { [K: string]: string[] | string } = {};
        recursiveParseFilter(options.filter, filter);
        if (Object.keys(filter).length !== 0) request.filter = filter;
    }
    if (options.sort && Array.isArray(options.sort) && options.sort.length) {
        const sort: { [K: string]: boolean } = {};
        parseSort(options.sort as SortDescription[], sort);
        if (Object.keys(sort).length !== 0) request.sort = sort;
    }

    return request;
}

function parseSort(
    values: Array<SortDescription>,
    sort: { [K: string]: boolean }
) {
    values.forEach((sortValue) => {
        const sortDesc = sortValue;
        sort[processKey(sortDesc.selector)] = sortDesc.desc;
    });
}

function recursiveParseFilter(
    value: string[] | Array<Array<string>>,
    filter: { [K: string]: string[] | string }
) {
    if (Array.isArray(value[0])) {
        value.forEach((filterValue: string[] | string) => {
            if (Array.isArray(filterValue)) {
                const key = processKey(filterValue[0]);
                const operation = filterValue[1];
                const value = filterValue[2];
                if (Array.isArray(key)) {
                    recursiveParseFilter(filterValue, filter);
                } else if (filterValue.length === 3)
                    if (operation === "=") {
                        if (Array.isArray(filter[key])) {
                            (filter[key] as string[]).push(value);
                        } else {
                            filter[key] = [value];
                        }
                    } else if (operation.includes(">")) {
                        filter[`${key}Start`] = value;
                    } else if (operation.includes("<")) {
                        filter[`${key}End`] = value;
                    }
            }
        });
    } else if (value.length === 3 && value[1] === "=") {
        filter[processKey(value[0])] = [value[2] as string];
    }
}

function processKey(key: string): string {
    if (key.includes(".")) {
        return key.split(".")[0];
    }
    return key;
}
