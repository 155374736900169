
import logo from "@/assets/img/BMW_logo_(white).svg";
import Component from "vue-class-component";
import Vue from "vue";
import CredentialsForm from "@/components/CredentialsForm";
import TwoFactorForm from "@/components/TwoFactorForm";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import { Routes } from "@/router/routes";
import Errors from "./BackendResponseErrors.json";
import notify from "devextreme/ui/notify";
import { LoginResult } from "@/models/LoginResult";
import LoginFormData from "../../models/LoginFormData";

@Component({
    components: { CredentialsForm, TwoFactorForm },
    methods: {
        ...accountModule.mapActions({
            logUserIn: AccountActions.LogUserIn,
            logUserInTwoFactor: AccountActions.LogUserInTwoFactor,
            getTwoFactorResetToken: AccountActions.GetTwoFactorResetToken
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors,
            getLoginResult: AccountGetters.LoginResult
        })
    }
})
export default class Login extends Vue {
    // Data
    protected logo: string = logo;
    protected twoFactorLogin = false;
    protected loginData: LoginFormData = new LoginFormData();

    // Mapped methods
    private readonly logUserIn!: (payload: {
        username: string;
        password: string;
    }) => Promise<void>;
    private readonly logUserInTwoFactor!: (payload: {
        twoFactorPassword: string;
    }) => Promise<void>;
    private readonly getTwoFactorResetToken!: (payload: {
        userName: string;
        recoveryCode: string;
    }) => Promise<string>;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly getLoginResult!: () => LoginResult | null;

    protected async submit(): Promise<void> {
        if (!this.twoFactorLogin) {
            await this.loginAsync();
            // A standard OTP has a length of 6. The form checks if it's 6 or 8 characters.
        } else if (this.loginData.twoFactorPassword.length == 6) {
            await this.twoFactorLoginAsync();
            // If it is not 6 characters long, it has to be 8 and is an emergency code.
        } else {
            await this.twoFactorResetAsync();
        }
    }

    private async loginAsync(): Promise<void> {
        await this.logUserIn({
            username: this.loginData.email,
            password: this.loginData.password
        });

        if (this.getLoginResult()?.isMultiFactorRequired) {
            this.twoFactorLogin = true;
            return;
        }

        this.showErrorDialogOrPush(
            (this.$route.query.redirecturl as string) ?? Routes.Home
        );
    }

    private async twoFactorLoginAsync(): Promise<void> {
        await this.logUserInTwoFactor({
            twoFactorPassword: this.loginData.twoFactorPassword
        });

        this.showErrorDialogOrPush(
            (this.$route.query.redirecturl as string) ?? Routes.Home
        );
    }

    private async twoFactorResetAsync(): Promise<void> {
        const token = await this.getTwoFactorResetToken({
            userName: this.loginData.email,
            recoveryCode: this.loginData.twoFactorPassword
        });

        this.showErrorDialogOrPush(
            Routes.TwoFactorActivation + "?token=" + token
        );
    }

    private showErrorDialogOrPush(pushTarget: string): void {
        const sessionErrors = this.getErrors();
        this.setErrors(null);

        if (sessionErrors !== undefined && sessionErrors !== null) {
            if (sessionErrors[0]?.includes(Errors.BadRequest.Error)) {
                notify(Errors.BadRequest.Message, "error", 10000);
            } else {
                notify(
                    sessionErrors[1] ?? Errors.Default.Message,
                    "error",
                    10000
                );
            }
        } else if (pushTarget !== Routes.Login) {
            this.$router.push(pushTarget);
        } else {
            this.$router.push(Routes.Home);
        }
    }
}
