
import Vue from "vue";
import Component from "vue-class-component";
import SideMenuItem from "../SideMenuItem";
import SideMenuTitle from "../SideMenuTitle";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import ReleaseEmailsPopup from "@/components/ReleaseEmailsPopup";
import CreateMaintenanceBannerPopup from "@/components/CreateMaintenanceBannerPopup";

export interface MenuItem {
    title: string;
    icon?: {
        name: string;
        mdi?: boolean;
    };
    to?: string;
    allowedRoles?: string[];
    action?: () => void;
}

interface MenuCategory extends MenuItem {
    items?: MenuItem[];
}

@Component({
    components: {
        SideMenuTitle,
        SideMenuItem,
        ReleaseEmailsPopup,
        CreateMaintenanceBannerPopup
    }
})
export default class SideMenu extends Vue {
    readonly menuCategories: MenuCategory[] = [
        {
            title: "Dashboard",
            to: Routes.Home
        },
        {
            title: "Requests",
            items: [
                {
                    title: "Create Request",
                    icon: { name: "add" },
                    to: Routes.CreateRequest,
                    allowedRoles: ["Admin", "SU"]
                },
                {
                    title: "Requests in Progress",
                    icon: { name: "requests-progress" },
                    to: Routes.RequestsInProgress
                },
                {
                    title: "Archive",
                    icon: { name: "archive" },
                    to: Routes.Archive
                },
                {
                    title: "Drafts",
                    icon: { name: "drafts" },
                    to: Routes.Drafts,
                    allowedRoles: ["Admin", "SU", "HV"]
                }
            ]
        },
        {
            title: "Configuration",
            allowedRoles: ["Admin", "SU", "MSU", "MU"],
            items: [
                {
                    title: "Markets",
                    icon: { name: "markets" },
                    to: Routes.Markets,
                    allowedRoles: ["Admin", "SU", "MSU", "MU"]
                },
                {
                    title: "Customers",
                    icon: { name: "customers" },
                    to: Routes.Customers,
                    allowedRoles: ["Admin", "SU"]
                },
                {
                    title: "Users",
                    icon: { name: "users" },
                    to: Routes.Users,
                    allowedRoles: ["Admin", "SU", "MSU"]
                },
                {
                    title: "Release Email",
                    icon: { name: "email", mdi: true },
                    action: this.showReleaseEmailsPopup,
                    allowedRoles: ["Admin"]
                },
                {
                    title: "Maintenance Banner",
                    icon: { name: "pin", mdi: true },
                    action: this.showCreateMaintenanceBannerPopup,
                    allowedRoles: ["Admin"]
                }
            ]
        }
    ];

    protected releaseEmailsPopup = false;
    protected createMaintenanceBannerPopup = false;

    isMenuItemVisible(roles?: string[]): boolean {
        if (roles) {
            return isUserInRoles(roles);
        }
        return true;
    }

    showReleaseEmailsPopup(): void {
        this.releaseEmailsPopup = true;
    }

    showCreateMaintenanceBannerPopup(): void {
        this.createMaintenanceBannerPopup = true;
    }
}
