
import logo from "@/assets/img/BMW_logo_(white).svg";
import playStoreLogo from "@/assets/img/play_store.png";
import appStoreLogo from "@/assets/img/app_store.png";
import Component from "vue-class-component";
import Vue from "vue";
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxButtonItem,
    DxPatternRule
} from "devextreme-vue/form";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { AccountMutations } from "@/store/modules/account/mutations";
import Errors from "./BackendResponseErrors.json";
import notify from "devextreme/ui/notify";
import { Routes } from "@/router/routes";
import { DxPopup, DxToolbarItem, DxPosition } from "devextreme-vue/popup";

@Component({
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxStringLengthRule,
        DxButtonItem,
        DxPatternRule,
        DxPopup,
        DxToolbarItem,
        DxPosition
    },
    methods: {
        ...accountModule.mapActions({
            getTwoFactorSetupCode: AccountActions.GetTwoFactorSetupCode,
            activateTwoFactor: AccountActions.ActivateTwoFactorAuthentication,
            verifyLogin: AccountActions.VerifyLogin
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors
        })
    }
})
export default class Login extends Vue {
    private logo: string = logo;
    private appStoreLogo: string = appStoreLogo;
    private playStoreLogo: string = playStoreLogo;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly getTwoFactorSetupCode!: (
        payload: string
    ) => Promise<string>;
    private readonly activateTwoFactor!: (payload: {
        token: string;
        code: string;
    }) => Promise<void>;
    private readonly verifyLogin!: () => Promise<void>;

    twoFactorModel = {
        twoFactorPassword: ""
    };
    twoFactorPasswordOptions = {
        placeholder: "Enter code",
        width: 200
    };
    twoFactorButtonOptions = {
        text: "Confirm",
        type: "default",
        height: 40,
        width: 200,
        useSubmitBehavior: true
    };
    tokenErrorPopupVisible = false;
    successPopupVisible = false;
    errorCloseButtonOptions = {
        type: "default",
        text: "Back to login",
        onClick: (): void => {
            this.$router.push(Routes.Login);
        }
    };

    successCloseButtonOptions = {
        type: "default",
        text: "Continue",
        onClick: (): void => {
            this.$router.push(
                (this.$route.query.redirecturl as string) ?? Routes.Home
            );
        }
    };

    async submit(): Promise<void> {
        const token = this.$route.query.token as string;
        await this.activateTwoFactor({
            token: token,
            code: this.twoFactorModel.twoFactorPassword
        });
        const sessionErrors = this.getErrors();
        this.setErrors(null);

        if (sessionErrors !== undefined && sessionErrors !== null) {
            if (sessionErrors[0].includes(Errors.BadRequest.Error)) {
                notify(Errors.BadRequest.Message, "error", 10000);
            } else {
                notify(
                    sessionErrors[1] ?? Errors.Default.Message,
                    "error",
                    10000
                );
            }
        } else {
            await this.verifyLogin();
            this.successPopupVisible = true;
        }
    }

    mounted(): void {
        this.getQrCodeData().then((data) => {
            const sessionErrors = this.getErrors();
            this.setErrors(null);

            if (sessionErrors !== undefined && sessionErrors !== null) {
                this.tokenErrorPopupVisible = true;
            } else {
                const qrCodeImageElement = document.getElementById(
                    "qrCode"
                ) as HTMLImageElement;
                qrCodeImageElement.src = data;
            }
        });
    }

    private async getQrCodeData(): Promise<string> {
        const token = this.$route.query.token as string;
        const data = await this.getTwoFactorSetupCode(token);
        return data;
    }
}
