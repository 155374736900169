
import Vue from "vue";

export default Vue.extend({
    props: {
        to: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        }
    }
});
