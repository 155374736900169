
import DxForm, {
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule,
    DxEmptyItem,
    DxButtonItem,
    DxPatternRule,
    DxGroupItem
} from "devextreme-vue/form";
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import LoginFormData from "@/models/LoginFormData";

@Component({
    components: {
        DxForm,
        DxLabel,
        DxSimpleItem,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule,
        DxEmptyItem,
        DxButtonItem,
        DxPatternRule,
        DxGroupItem
    },
    props: {
        loginData: LoginFormData
    }
})
export default class CredentialsForm extends Vue {
    protected passwordResetRoute = Routes.RequestPasswordReset;
    passwordOptions = {
        placeholder: "Your password",
        mode: "password"
    };
    emailOptions = {
        placeholder: "Enter email address"
    };
    buttonOptions = {
        text: "Login",
        type: "default",
        height: 40,
        width: 120,
        useSubmitBehavior: true
    };
}
