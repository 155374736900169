export interface RequestGridConfig {
    typeOfRequest: boolean;
    customer: boolean;
    customerType: boolean;
    expectedVolume: boolean;
    customerStatus: boolean;
    marketDeadline: boolean;
    customerDeadline: boolean;
    stateTool: boolean;
    IKAM: boolean;
    comment: boolean;
    supplyScenarios: boolean;
    totalFleet: boolean;
    countMarkets: boolean;
    modifiedBy: boolean;
}

export const defaultConfig: RequestGridConfig = {
    typeOfRequest: true,
    customer: true,
    customerType: true,
    expectedVolume: true,
    customerStatus: true,
    marketDeadline: false,
    customerDeadline: true,
    stateTool: true,
    IKAM: true,
    comment: true,
    supplyScenarios: false,
    totalFleet: false,
    countMarkets: false,
    modifiedBy: false
};
