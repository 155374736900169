import { AxiosError, AxiosResponse } from "axios";
import { Commit } from "vuex";
import { getValidationErrorTextsFromErrorResponse } from "./responseErrorUtils";
import { VirusScanResult } from "@/models/VirusScanResult";

export async function makeRequest<T>(
    commit: Commit,
    errorMutations: string,
    loadingMutation: string,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void,
    onFailure?: (error: AxiosError) => void
): Promise<void> {
    commit(errorMutations, null);
    try {
        commit(loadingMutation, true);
        const result = await callback();
        if (onSuccess) {
            onSuccess(result);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        if (onFailure !== undefined) {
            onFailure(error);
        } else {
            const response: AxiosResponse = error.response;

            if (response) {
                commit(
                    errorMutations,
                    getValidationErrorTextsFromErrorResponse(error)
                );
            } else {
                commit(errorMutations, "Network error!");
            }
        }
    } finally {
        commit(loadingMutation, false);
    }
}

export async function saveAttachment(
    id: number,
    attachments: File[],
    removeAttachmentsId: number[],
    addAttachment: (mid: number, value: File) => Promise<AxiosResponse>,
    removeAttachment: (id: number, value: number) => Promise<AxiosResponse>,
    onError?: (attachment: File | number, errors: string[]) => void
): Promise<void> {
    await attachmentRequest(id, attachments, addAttachment, onError);
    await attachmentRequest(id, removeAttachmentsId, removeAttachment, onError);
}

async function attachmentRequest<T>(
    id: number,
    attachments: T[],
    request: (marketId: number, value: T) => Promise<AxiosResponse>,
    onError?: (attachment: T, errors: string[]) => void
) {
    if (attachments.length) {
        const localAttachments = [...attachments];
        for (const attachment of localAttachments) {
            try {
                await request(id, attachment);
            } catch (error) {
                if (!onError) throw error;

                const axiosError = error as AxiosError;
                let errorMessages: string[] = [];
                if (
                    axiosError.response?.data &&
                    axiosError.response.data.hasVirus
                ) {
                    const response = axiosError.response
                        .data as VirusScanResult;
                    const infectedFilePreamble =
                        "The uploading attachment was infected";
                    errorMessages = [
                        infectedFilePreamble,
                        ...response.virusSignatures
                    ];
                } else if (
                    axiosError.response?.data &&
                    (typeof axiosError.response.data === "string" ||
                        axiosError.response.data instanceof String)
                ) {
                    errorMessages = [axiosError.response.data as string];
                } else {
                    errorMessages = getValidationErrorTextsFromErrorResponse(
                        error as Error
                    );
                }

                onError(attachment, errorMessages);
            }
        }
    }
}
