
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { AccountActions } from "./store/modules/account/actions";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";

@Component({
    components: {
        MainLayout,
        AuthLayout
    },
    mounted() {
        if (!this.$route.matched.some((record) => record.meta.allowAnonymous)) {
            store.dispatch("account/" + AccountActions.VerifyLogin);
        }
    }
})
export default class App extends Vue {
    created(): void {
        loadMessages(deMessages);
        locale("de");
    }
    get layout(): string {
        return this.$route?.meta?.layout ?? "MainLayout";
    }
}
