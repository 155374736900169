import { Market } from "@/models/market/Market";
import { MarketInfo } from "@/models/market/MarketInfo";
import { MarketDetails } from "@/models/market/MarketDetails";
import api from ".";
import { AxiosResponse } from "axios";

function getMarkets(): Promise<Market[]> {
    return api.get<Market[]>("markets").then((response) => response.data);
}

function getMarketDetails(marketId: number): Promise<MarketDetails> {
    return api
        .get<MarketDetails>(`markets/${marketId}`)
        .then((response) => response.data);
}

function getMarketInfo(): Promise<MarketInfo> {
    return api
        .get<MarketInfo>("markets/info")
        .then((response) => response.data);
}

function createMarket(market: MarketDetails): Promise<number> {
    return api
        .post<number>("markets", market)
        .then((response) => response.data);
}

function deleteMarket(marketId: number): Promise<AxiosResponse> {
    return api.delete(`markets/${marketId}`);
}

function updateMarket(market: MarketDetails): Promise<AxiosResponse> {
    return api.put(`markets/${market.marketId}`, market);
}

function uploadAttachment(
    marketId: number,
    attachment: File
): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("attachment", attachment);
    return api.post(`markets/${marketId}/attachments`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

function deleteAttachment(
    marketId: number,
    attachmentId: number
): Promise<AxiosResponse> {
    return api.delete(`markets/${marketId}/attachments/${attachmentId}`);
}

async function exportMarkets(): Promise<{ filename: string; data: Blob }> {
    return api
        .post(`markets/export`, null, { responseType: "blob" })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename="(.+)"/)[1]
            };
        });
}

export default {
    getMarkets,
    getMarketDetails,
    getMarketInfo,
    createMarket,
    deleteMarket,
    updateMarket,
    uploadAttachment,
    deleteAttachment,
    exportMarkets
};
