import { Market } from "../market/Market";
import { VehicleModel } from "../VehicleModel";
import { QuestionAnswer } from "../QuestionAnswer";
import { User } from "../User";
import { MarketRequestStatus } from "./MarketRequestStatus";
import { Attachment } from "../Attachment";

export enum SendMarketRequestType {
    None,
    Commit,
    Approve,
    WithdrawApproval,
    RequestUpdate,
    Clarification
}

export enum MarketRequestState {
    Open = "Open",
    UpdateRequested = "Update requested",
    Approved = "Approved",
    Committed = "Committed"
}

export interface MarketRequest {
    marketRequestId?: number;
    marketId: number;
    market: Market;
    state: MarketRequestState;
    usersTo: User[];
    usersCc: User[];
    models?: VehicleModel[];
    answers?: QuestionAnswer[];
    statusHistory?: MarketRequestStatus[];
    attachments?: Attachment[];
}
