import { VehicleModel } from "@/models/VehicleModel";
import { ModelsInfo } from "@/models/ModelsInfo";
import api from ".";

function getModels(): Promise<VehicleModel[]> {
    return api.get("vehicles").then((r) => r.data);
}

function getModelsInfo(): Promise<ModelsInfo> {
    return api.get("vehicles/info").then((r) => r.data);
}

export default {
    getModels,
    getModelsInfo
};
