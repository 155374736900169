import compareRequestApi from "@/api/compareRequestApi";
import marketRequestApi from "@/api/marketRequestApi";
import { RequestComparisonSettings } from "@/models/request/compare/RequestComparisonSettings";
import { makeRequest } from "@/services/requestUtils";
import { getValidationErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { saveData } from "@/services/saveData";
import { RootState } from "@/store";
import { AxiosError } from "axios";
import { ActionTree, Commit } from "vuex";
import { MarketRequestCompareState } from ".";
import { MarketRequestCompareMutations } from "./mutations";

export enum MarketRequestCompareActions {
    LoadComparisonSettingsForMarketRequest = "loadComparisonSettingsForMarketRequest",
    GetMarketRequestIdForRequestByMarket = "getMarketRequestIdForRequestByMarket",
    LoadComparisonDetails = "loadComparisonDetails",
    CreateComparisonSettings = "createComparisonSettings",
    UpdateComparisonSettings = "updateComparisonSettings",
    DeleteComparisonSettings = "deleteComparisonSettings",
    Export = "export"
}

async function makeModuleRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void,
    onFailure?: (error: AxiosError) => void
): Promise<void> {
    await makeRequest(
        commit,
        MarketRequestCompareMutations.SetErrors,
        MarketRequestCompareMutations.SetLoading,
        callback,
        onSuccess,
        onFailure
    );
}

const actions: ActionTree<MarketRequestCompareState, RootState> = {
    async [MarketRequestCompareActions.LoadComparisonSettingsForMarketRequest](
        { commit },
        payload: number
    ) {
        await makeModuleRequest(
            commit,
            () =>
                compareRequestApi.getCompareSettingsByMarketRequestId(payload),
            (settings) =>
                commit(
                    MarketRequestCompareMutations.SetComparisonSettings,
                    settings
                ),
            (error) => {
                const axiosError = error as AxiosError;
                // If comparison settings doesn't exist is not an error, this means a user hasn't created one yet
                // So this case should process as expected behavior
                if (axiosError.response?.status === 404) {
                    commit(
                        MarketRequestCompareMutations.SetComparisonSettings,
                        null
                    );
                } else {
                    commit(
                        MarketRequestCompareMutations.SetErrors,
                        getValidationErrorTextsFromErrorResponse(axiosError)
                    );
                }
            }
        );
    },
    async [MarketRequestCompareActions.GetMarketRequestIdForRequestByMarket](
        { commit },
        payload: { requestId: number; marketId: number }
    ) {
        let marketRequestId: number | null = null;
        await makeModuleRequest(
            commit,
            () =>
                marketRequestApi.getMarketRequestForRequestByMarket(
                    payload.requestId,
                    payload.marketId
                ),
            (marketRequest) =>
                (marketRequestId = marketRequest?.marketRequestId ?? null)
        );
        return marketRequestId;
    },
    async [MarketRequestCompareActions.LoadComparisonDetails](
        { commit },
        payload: {
            originalMarketRequestId: number;
            comparedMarketRequestId: number;
        }
    ) {
        await makeModuleRequest(
            commit,
            () =>
                compareRequestApi.getComparisonDetails(
                    payload.originalMarketRequestId,
                    payload.comparedMarketRequestId
                ),
            (details) =>
                commit(
                    MarketRequestCompareMutations.SetComparisonDetails,
                    details
                )
        );
    },
    async [MarketRequestCompareActions.CreateComparisonSettings](
        { commit, dispatch },
        payload: RequestComparisonSettings
    ) {
        await makeModuleRequest(commit, () =>
            compareRequestApi.createComparisonSettings(payload)
        );
        await dispatch(
            MarketRequestCompareActions.LoadComparisonSettingsForMarketRequest,
            payload.originalMarketRequestId
        );
    },
    async [MarketRequestCompareActions.UpdateComparisonSettings](
        { state, commit },
        payload: RequestComparisonSettings
    ) {
        payload.originalSupplyScenarioId =
            state.originalRequestSelectedSupplyScenario?.supplyScenarioId ??
            null;
        payload.comparedSupplyScenarioId =
            state.comparedRequestSelectedSupplyScenario?.supplyScenarioId ??
            null;
        await makeModuleRequest(commit, () =>
            compareRequestApi.updateComparisonSettings(payload)
        );
        commit(MarketRequestCompareMutations.SetComparisonSettings, payload);
    },
    async [MarketRequestCompareActions.DeleteComparisonSettings](
        { commit },
        payload: number
    ) {
        await makeModuleRequest(commit, () =>
            compareRequestApi.deleteComparisonSettings(payload)
        );
        commit(MarketRequestCompareMutations.SetComparisonSettings, null);
    },
    async [MarketRequestCompareActions.Export]({ commit }, payload: number) {
        await makeModuleRequest(
            commit,
            () => compareRequestApi.exportComparison(payload),
            (file) => saveData(file.data, file.filename)
        );
    }
};

export default actions;
