import api from ".";
import { MaintenanceBanner } from "@/models/MaintenanceBanner";

function getMaintenanceBanner(): Promise<MaintenanceBanner> {
    return api
        .get<MaintenanceBanner>("maintenance-banner")
        .then((response) => response.data);
}

function createMaintenanceBanner(
    maintenanceBanner: MaintenanceBanner
): Promise<void> {
    return api
        .post("maintenance-banner", maintenanceBanner)
        .then((response) => response.data);
}

export default {
    getMaintenanceBanner,
    createMaintenanceBanner
};
