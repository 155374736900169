import { VehicleModel } from "@/models/VehicleModel";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import { ModelsInfo } from "../../../models/ModelsInfo";
import mutations from "./mutations";

export interface ModelState {
    models: VehicleModel[];
    info: ModelsInfo | null;
    errors: string[] | null;
}

const modelState: ModelState = {
    models: [],
    info: null,
    errors: null
};

const model: Module<ModelState, RootState> = {
    namespaced: true,
    state: modelState,
    actions: actions,
    getters: getters,
    mutations: mutations
};

export const modelModule = createNamespacedHelpers("model");

export default model;
